import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export function SitemarkIcon() {
  return (
    <SvgIcon sx={{ height: 21, width: 100, mr: 2 }}>
      <svg width="368" height="81" viewBox="0 0 368 81" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M42.7998 80.9379C64.8748 80.9379 82.7998 63.0647 82.7998 40.9793C82.7998 18.8939 64.8748 1 42.7998 1C20.7248 1 2.7998 18.8939 2.7998 40.9793C2.7998 63.0647 20.6937 80.9379 42.7998 80.9379Z" fill="#A50034"/>
<path d="M40.7659 23.2647V58.6731H51.9525V55.496H43.9858V23.2647H40.7659Z" fill="white"/>
<path d="M29.5585 32.9216C32.2175 32.9216 34.3676 30.7478 34.3676 28.0827C34.3676 25.4177 32.2175 23.2334 29.5585 23.2334C26.8994 23.2334 24.7493 25.4177 24.7493 28.0827C24.7493 30.7478 26.8994 32.9216 29.5585 32.9216Z" fill="white"/>
<path d="M43.9858 7.19093C43.5703 7.17002 42.7705 7.14912 42.3862 7.14912C23.856 7.14912 8.77427 22.3241 8.77427 40.9585C8.77427 50.0092 12.2746 58.4955 18.6003 64.8707C24.957 71.2354 33.3912 74.7574 42.3862 74.7574C51.3812 74.7574 59.7738 71.2354 66.1202 64.8707C72.4666 58.4955 75.9774 50.0092 75.9774 40.9585V39.4535H51.9733V42.568H72.7678C72.7678 42.6307 72.7678 42.9651 72.7574 43.0487C71.698 58.9762 58.4755 71.5907 42.3758 71.5907C34.2221 71.5907 26.5878 68.4031 20.8334 62.6132C15.0687 56.8442 11.8799 49.1626 11.8799 40.9585C11.8799 32.7544 15.0583 25.0728 20.8334 19.2933C26.5878 13.5139 34.2221 10.3263 42.3758 10.3263C42.729 10.3263 43.5807 10.3263 43.9754 10.3472V7.20138L43.9858 7.19093Z" fill="white"/>
<path d="M110.584 15.8601H99V66.1401H134.59V56.3501H110.584V15.8601ZM169.511 46.9801V55.7701C167.775 56.4301 164.383 57.1001 161.15 57.1001C150.723 57.1001 147.241 51.7901 147.241 41.0101C147.241 30.2301 150.554 24.6601 160.98 24.6601C166.777 24.6601 170.09 26.4901 172.814 29.9701L180.017 23.3301C175.627 17.0301 167.925 15.1201 160.731 15.1201C144.507 15.1201 135.977 24.0001 135.977 40.9201C135.977 57.8401 143.669 66.8801 160.641 66.8801C168.423 66.8801 176.036 64.8901 180.177 61.9901V37.6001H160.062V46.9801H169.511Z" fill="#6B6B6B"/>
<path d="M239.204 21.8001C234.864 16.9701 227.839 15.1701 221.424 15.1701C205.669 15.1701 197.139 24.0101 197.139 40.9901C197.139 57.9701 203.674 66.8201 221.284 66.8201C227.48 66.8201 234.035 65.3701 238.445 62.7501L235.971 54.3901C232.389 56.1801 227.081 57.3601 222.052 57.3601C210.967 57.3601 208.244 50.6601 208.244 40.8501C208.244 29.4601 213.093 24.0001 221.843 24.0001C226.532 24.0001 230.453 26.0701 233.147 28.8301L239.214 21.7901L239.204 21.8001ZM243.105 15.7201V65.9901H253.102V40.2301C254.479 39.3301 256.754 38.4301 258.41 38.4301C263.03 38.4301 264.756 39.8101 264.756 44.1601V65.9801H274.744V41.9501C274.744 35.0501 272.06 30.2801 263.649 30.2801C259.717 30.2801 256.066 31.3901 253.112 32.7701V15.7201H243.105ZM311.321 47.7001C311.321 37.7601 306.841 30.3001 295.746 30.3001C284.651 30.3001 278.864 36.9301 278.864 48.3901C278.864 60.5501 283.274 66.8301 296.235 66.8301C302.371 66.8301 308.018 64.9601 310.702 63.1701L308.837 56.6101C305.873 58.1301 301.533 59.2301 298.021 59.2301C294.509 59.2301 291.895 58.1901 290.787 57.0201C289.48 55.5701 288.852 53.2201 288.852 50.8001H311.311V47.6901L311.321 47.7001ZM301.463 44.3801H288.862C289.001 39.5501 291.346 37.2001 295.477 37.2001C299.607 37.2001 301.473 40.1701 301.473 44.3801M315.522 66.0001H325.439V40.3801C326.337 39.6201 328.133 38.7201 329.989 38.7201C334.259 38.7201 335.367 40.0301 335.367 44.4501V65.9901H345.215V44.5801C345.215 43.0601 345.015 41.3301 344.736 40.2301C345.704 39.2601 347.559 38.7101 349.216 38.7101C353.695 38.7101 355.142 40.7801 355.142 45.0001V65.9901H365V42.5101C365 35.3301 361.967 30.3501 353.486 30.3501C350.114 30.3501 346.113 31.3201 342.88 33.3901C341.433 31.4501 339.088 30.2801 335.646 30.2801C332.204 30.2801 328.542 31.1401 325.15 33.6601V31.1701H315.522V66.0001Z" fill="#6B6B6B"/>
</svg>

    </SvgIcon>
  );
}

