import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { LicenseInfo } from '@mui/x-license-pro';
import { RecoilRoot } from 'recoil';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MDLPage from './pages/mdlPage';
import TRPage from './pages/trPage';
import Layout from './layout/layout';
import SignInSide from './login/SignIn';
import ForgotPassword from './login/ForgotPassword';
import SignUp from './login/SignUp';
import PrivateRoute from './common/PrivateRoute';
import ResetPassword from './login/ResetPassword';
import VPpage from './pages/vpPage';
import VTRPage from './pages/vtrPage';

document.title = "LGC";
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RecoilRoot>
    <React.StrictMode>
      <Router>
        <Routes>
          <Route path="/login" element={<SignInSide />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/" element={<PrivateRoute />}>
            <Route path="/" element={<Layout />}>
              <Route path="/mdl" element={<MDLPage />} />
              <Route path="/tr" element={<TRPage />} />
              <Route path='/vp' element={<VPpage/>}/>
              <Route path='/vtr' element={<VTRPage/>}/>
            </Route>
          </Route>
        </Routes>
      </Router>
    </React.StrictMode>
  </RecoilRoot>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
