import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import Swal from 'sweetalert2';
import dayjs from 'dayjs';
import { Box, ButtonGroup, Button, Alert, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreIcon from '@mui/icons-material/Restore';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import UploadIcon from '@mui/icons-material/Upload';
import DownloadIcon from '@mui/icons-material/Download';
import SearchIcon from '@mui/icons-material/Search';
import { darken } from '@mui/material/styles';
import {
    GridEditDateCell, gridClasses,
    DataGridPremium, GridToolbarExport, GridToolbarContainer, GridToolbarQuickFilter, useKeepGroupedColumnsHidden,
    useGridApiRef, GridEditSingleSelectCell, GridActionsCellItem, GRID_ROOT_GROUP_ID, GridEditInputCell
} from '@mui/x-data-grid-premium';
import Snackbar from '@mui/material/Snackbar';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import {
    randomId,
} from '@mui/x-data-grid-generator';
import axios from "../common/axios"
import { Circles } from 'react-loader-spinner';
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { styled } from '@mui/material/styles';
import _ from 'lodash';
import { read, utils, writeFile } from 'xlsx-js-style';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useDropzone } from 'react-dropzone';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { IconButton } from '@mui/material';

dayjs.extend(isSameOrBefore);

const parseExcelDateWithOneMinuteAdjustment = (excelDate) => {
    const date = new Date(dayjs(excelDate).add(1, "minutes"))
    return date;
};

const DatesOrderValid = (firstDate, secondDate) => {
    if (!firstDate || !secondDate) {
        return true;
    }
    // 시간 부분을 제거하여 순수한 날짜만을 비교합니다.
    const pureFirstDate = dayjs(firstDate).startOf('day');
    const pureSecondDate = dayjs(secondDate).startOf('day');
    return pureFirstDate.isSameOrBefore(pureSecondDate);
};

const dropzoneStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: '2px',
    borderRadius: '2px',
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    cursor: 'pointer',
    width: '400px', // 너비 고정
    boxSizing: 'border-box', // 패딩과 보더가 너비에 포함되도록 설정

};

const activeStyle = {
    borderColor: '#2196f3',
};


const staticFields = [
    { displayName: "PO Title", key: "POBPK" },
    { displayName: "Deliverable No.", key: "DeliverableNotxt" },
    { displayName: "Deliverable Title", key: "ugenSubTitlSDT120" },
    { displayName: "Issue Stage Group", key: "IssueStageGroupDPK" },
    { displayName: "Issue Purpose", key: "IssuePurposePD" },
    { displayName: "Weight Value", key: "DeliverableWV" },
    { displayName: "Issue Stage", key: "IssueStage" },
    { displayName: "Rev #", key: "usubRevNumberTB8" },

];

const VPtable = ({ initialRows, stageData, groupData, projectCode, poData }) => {

    console.log(initialRows)


    const handleUserAction = async (actionType, data) => {
        try {
            const description = `User performed ${actionType} action with data in Vendor Print: ${JSON.stringify(data)}`; // 로그 설명을 생성합니다.

            await axios.post('/logging', {
                description,
            });
        } catch (error) {
            console.error('Error logging user action:', error);
        }
    };


    const [rows, setRows] = React.useState(initialRows);


    const [openFileUpload, setOpenFileUpload] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState(null);
    const [error, setError] = useState(null);
    const [snackbar, setSnackbar] = React.useState(null);
    const apiRef = useGridApiRef();
    const [rowSelectionModel, setRowSelectionModel] = React.useState([]);

    const [hasUnsavedRows, setHasUnsavedRows] = React.useState(false);
    const [isSaving, setIsSaving] = React.useState(false);

    const IssuePurpose = ['Issue Purpose', 'For Information', 'For Approval'];

    const initialState = {
        rowGrouping: {
            model: ['POBPK'],
        },
    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: (acceptedFiles, rejectedFiles) => {
            if (acceptedFiles.length > 1 || rejectedFiles.length > 1) {
                setError('한 번에 하나의 파일만 업로드 가능합니다.');
            } else if (rejectedFiles.length > 0) {
                setError('xlsx 파일만 업로드 가능합니다.');
            } else if (acceptedFiles.length === 1) {
                setSelectedFiles(acceptedFiles[0]);
                setError(null);
            }
        },
        accept: {
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
        },
        multiple: false,
    });

    const [selectedRow, setSelectedRow] = React.useState({});
    const [openGroupDataModal, setOpenGroupDataModal] = React.useState(false);



    const handleOpenGroupDataModal = (params) => {
        console.log(params)
        setSelectedRow(params);
        setOpenGroupDataModal(true);
    };

    const handleCloseGroupDataModal = () => {
        setOpenGroupDataModal(false);
    };

    const handleSelectGroupData = (selectedData) => {
        const updatedRow = {
            ...selectedRow,
            id: selectedRow.id,
            IssueStageGroupDPK: selectedData.ISGroupName,
            IssuePurposePD: selectedData.IssuePurposePD,
            description: selectedData.description,

        };

        setRows(prevRows => {
            // Remove the updated row from its current position
            const filteredRows = prevRows.filter(row => row.id !== updatedRow.id);
            // Add the updated row to the beginning of the array
            return [updatedRow, ...filteredRows];
        });

        apiRef.current.updateRows([updatedRow]);

        // Scroll to the top of the grid
        apiRef.current.scrollToIndexes({ rowIndex: 0 });

        setOpenGroupDataModal(false);
    };


    const handleRemoveFile = () => {
        setSelectedFiles(null);
        setError(null);
    };
    const handleOpenFileUpload = () => {
        setOpenFileUpload(true); // 열린 파일 업로드 대화상자의 rowId를 설정
    };

    const handleCloseFileUpload = () => setOpenFileUpload(false);


    const unsavedChangesRef = React.useRef({
        unsavedRows: {},
        rowsBeforeChange: {},
    });

    const handleCloseSnackbar = () => setSnackbar(null);

    //column 생성

    const suffixes = ["IssuePlanStart", "IssueForecastStart", "IssueActualStart", "ApprovedDate", "TOCLVTRNoDPK", "TOCOVTRNoDPK", "MDLResult"];
    const suffixes2 = ["Plan Start", "Forecast Start", "Actual Start", "Approved", "TR No (TR to Client)", "TR No (TR to Contractor)", "Result"];

    const columnsDate = stageData.flatMap(v =>
        suffixes.map((suffix, index) => ({
            field: v + "//" + suffix,
            group: v,
            width: 80,
            type: suffixes[index].includes("Start") || suffixes[index] === "ApprovedDate" ? "date" : "text",
            editable: suffixes[index].includes("Start") || suffixes[index] === "ApprovedDate" ? true : false,
            renderEditCell:


                (params) => {
                    const foundGroup = groupData.find(
                        f => f.ISGroupName === params.row.IssueStageGroupDPK
                    );

                    if (rows.length > 0 && !rows.some(row => row.isNew) && !foundGroup) {
                        setSnackbar({
                            children: "입력한 정보의 도서/도면 그룹은 없는 상태입니다. LGC에 연락하여 그룹 생성 요청바랍니다.",
                            severity: 'warning',
                        });
                    }
                    return (
                        suffixes[index].includes("Start") || suffixes[index] === "ApprovedDate" ?
                            <GridEditDateCell
                                {...params}
                            // disabled={disabled}
                            />
                            :
                            <GridEditInputCell
                                {...params}
                            // disabled={true}
                            />
                    );

                },

            headerName: suffixes2[index],
            cellClassName: (params) => {
                const foundGroup = groupData.find(
                    f => f.ISGroupName === params.row.IssueStageGroupDPK

                );

                if (params.rowNode.type === "group") {
                    return 'groupRow'
                }

                return foundGroup?._bp_lineitems?.map(vf => vf.IssueStageDPK).includes(v) ? 'matched' : 'notmatched';
            },
        }))
    );


    const columnGroupingModel = useMemo(() => [

        {
            groupId: "docu",
            headerName: "Deliverable Information",
            freeReordering: true,
            description: '', // You can add descriptions if needed
            children: [{ field: "DeliverableNotxt" }, { field: "ugenSubTitlSDT120" }, { field: "IssuePurposePD" }, { field: "DeliverableWV" }]
        },
        {
            groupId: "latest",
            headerName: "Latest Information",
            freeReordering: true,
            description: '', // You can add descriptions if needed
            children: [{ field: "IssueStage" }, { field: "usubRevNumberTB8" }]
        },
        ...stageData.map(v => ({
            groupId: v, // Use the value from columnData as groupId
            headerName: v,
            freeReordering: true,
            description: '', // You can add descriptions if needed
            children: suffixes.map((suffix, index) => ({
                field: v + "//" + suffix,
                // Include other properties as needed
            }))
        }))
    ], [stageData, suffixes]);


    const columnsData = [
        {
            field: 'actions',
            type: 'actions',
            renderCell: (params) => {
                if (params.rowNode.type === "group") {
                    return null; // 그룹된 행에서는 action 아이콘을 숨김
                }
                return (
                    <div>
                        <GridActionsCellItem
                            icon={<RestoreIcon />}
                            label="Discard changes"
                            disabled={unsavedChangesRef.current.unsavedRows[params.id] === undefined || params.row.isNew}
                            onClick={() => {
                                apiRef.current.updateRows([
                                    unsavedChangesRef.current.rowsBeforeChange[params.id],
                                ]);
                                delete unsavedChangesRef.current.rowsBeforeChange[params.id];
                                delete unsavedChangesRef.current.unsavedRows[params.id];
                                setHasUnsavedRows(
                                    Object.keys(unsavedChangesRef.current.unsavedRows).length > 0,
                                );
                            }}
                        />
                        <GridActionsCellItem
                            icon={<DeleteIcon />}
                            label="Delete"
                            onClick={() => {
                                unsavedChangesRef.current.unsavedRows[params.id] = {
                                    ...params.row,
                                    _action: 'delete',
                                };
                                if (!unsavedChangesRef.current.rowsBeforeChange[params.id]) {
                                    unsavedChangesRef.current.rowsBeforeChange[params.id] = params.row;
                                }
                                setHasUnsavedRows(true);
                                apiRef.current.updateRows([params.row]); // to trigger row render
                            }}
                        />
                    </div>
                );
            },
        },
        {
            field: "id",
            editable: false,
            width: 30
        },

        {
            field: 'POBPK', headerName: "PO Title", width: 180, editable: true, type: 'singleSelect', valueOptions: poData,
            cellClassName: (params) => {
                if (params.row.isEditMode) {
                    return 'matched'
                }
                return '';
            },
        },

        {
            field: 'DeliverableNotxt', headerName: "Deliverable No.", width: 150, editable: true,
            cellClassName: (params) => {
                if (params.row.isEditMode) {
                    return 'matched'
                }
                return '';
            },
        },
        {
            field: 'ugenSubTitlSDT120', headerName: "Deliverable Title", width: 180, editable: true,
            cellClassName: (params) => {
                if (params.row.isEditMode) {
                    return 'matched'
                }
                return '';
            },
        },

        {
            field: 'selectIssueGroup',
            headerName: '',
            width: 40,
            renderCell: (params) => {
                if (params.rowNode.type === "group") {
                    return null; // 그룹된 행에서는 action 아이콘을 숨김
                }
                return (
                    <IconButton
                        size="small"
                        sx={{ fontSize: '9px' }}
                        onClick={() => handleOpenGroupDataModal(params.row)}
                    >
                        <SearchIcon fontSize='small' />
                    </IconButton>
                )
            },
        },

        {
            field: 'IssueStageGroupDPK',
            headerName: "Issue Stage Group",
            width: 160,
            editable: false,
            valueGetter: (params) => params,
            renderCell: (params) => {
                if (
                    params.rowNode.type === 'group' &&
                    params.field === params.rowNode.groupingField
                ) {
                    return '';
                }
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ marginLeft: 2, marginRight: 8 }}>
                            {params.value}
                        </span>
                    </Box>
                );
            },

        },

        {
            field: 'IssuePurposePD',
            headerName: "Issue Purpose",
            width: 120,
            editable: true,
            valueGetter: (params) => params,
            renderCell: (params) => {
                if (
                    params.rowNode.type === 'group' &&
                    params.field === params.rowNode.groupingField
                ) {
                    return '';
                }
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ marginLeft: 2, marginRight: 8 }}>
                            {params.value}
                        </span>
                    </Box>
                );
            },

        },


        {
            field: 'DeliverableWV', width: 80, editable: true,
            cellClassName: (params) => {
                if (params.row.isEditMode) {
                    return 'matched'
                }
                return '';
            },
            renderHeader: () => (<strong style={{ fontSize: 11 }} title='Weight Value'>WV</strong>),
        },

        { field: 'IssueStage', headerName: "Issue Stage", width: 120, editable: false, },
        { field: 'usubRevNumberTB8', headerName: "Rev #", width: 80, editable: false, },


    ];


    const columns = useMemo(() => [...columnsData, ...columnsDate], [columnsData, columnsDate]);


    const toggleGroup = () => {
        const groups = apiRef.current.getRowNode(GRID_ROOT_GROUP_ID).children;

        if (groups.length > 1) {
            groups.forEach(groupId => {
                apiRef.current.setRowChildrenExpansion(
                    groupId,
                    !apiRef.current.getRowNode(groupId).childrenExpanded,
                );
            });
        }
    };

    const processRowUpdate = React.useCallback((newRow, oldRow) => {
        const rowId = newRow.id;

        unsavedChangesRef.current.unsavedRows[rowId] = newRow;
        if (!unsavedChangesRef.current.rowsBeforeChange[rowId]) {
            unsavedChangesRef.current.rowsBeforeChange[rowId] = oldRow;
        }
        setHasUnsavedRows(true);
        return newRow;
    }, []);

    const discardChanges = React.useCallback(() => {
        setHasUnsavedRows(false);

        const rowsToUpdate = Object.values(unsavedChangesRef.current.rowsBeforeChange).filter(
            (row) => !unsavedChangesRef.current.unsavedRows[row.id]?.isNew
        );

        const rowsToDelete = Object.values(unsavedChangesRef.current.unsavedRows).filter(
            (row) => row.isNew
        );

        apiRef.current.updateRows(rowsToUpdate);
        apiRef.current.updateRows(rowsToDelete.map((row) => ({ ...row, _action: 'delete' })));

        unsavedChangesRef.current = {
            unsavedRows: {},
            rowsBeforeChange: {},
        };
    }, [apiRef]);

    const saveChanges = React.useCallback(async () => {
        try {
            setIsSaving(false);

            const updatedRows = Object.values(unsavedChangesRef.current.unsavedRows);
            const deletedRowIds = updatedRows
                .filter((row) => row._action === 'delete')
                .map((row) => row.id);

            const requiredFields = ['IssueStageGroupDPK', 'POBPK', 'DeliverableNotxt', 'ugenSubTitlSDT120'];
            const requiredFieldsName = ["Issue Stage Group", 'PO Title', 'Deliverable No.', 'Deliverable Title'];

            const missingFields = updatedRows.filter((row) =>
                requiredFields.some((field) => !row[field] && !deletedRowIds.includes(row.id))
            );

            const deliverableNotxtValues = updatedRows.map((row) => row.DeliverableNotxt);
            const duplicateDeliverableNotxt = deliverableNotxtValues.filter(
                (value, index) => deliverableNotxtValues.indexOf(value) !== index
            );

            if (duplicateDeliverableNotxt.length > 0) {
                const duplicateDeliverableNotxtMessage = duplicateDeliverableNotxt
                    .map((value) => `Duplicate Deliverable No.: ${value}`)
                    .join('\n');

                setSnackbar({
                    children: `Deliverable No.가 중복되었습니다:\n${duplicateDeliverableNotxtMessage}`,
                    severity: 'error',
                });
                return;
            }

            if (missingFields.length > 0) {
                const missingFieldsMessage = missingFields
                    .map((row) => {
                        const missingFieldNames = requiredFields
                            .map((field, index) => (!row[field] ? requiredFieldsName[index] : null))
                            .filter((name) => name !== null)
                            .join(', ');

                        return `Row ${row.id}: ${missingFieldNames}`;
                    })
                    .join('\n');

                setSnackbar({
                    children: `필수값이 없습니다:\n${missingFieldsMessage}`,
                    severity: 'error',
                });
                return;
            }


            setRows((prevRows) => {
                const existingRows = prevRows.map((row) => {
                    const updatedRow = updatedRows.find((r) => r.id === row.id);
                    if (updatedRow && updatedRow._action !== 'delete') {
                        // groupData에서 해당 행의 값을 찾아 업데이트
                        const findParams = groupData.find(v => v.ISGroupName === updatedRow.IssueStageGroupDPK);
                        return {
                            ...row,
                            ...updatedRow,
                            IssueStageGroupDPK: findParams?.ISGroupName,
                            isNew: false,
                            isEditMode: false
                        };
                    }
                    return row;
                });

                const newRows = updatedRows.filter(
                    (row) => !prevRows.some((r) => r.id === row.id) && row._action !== 'delete'
                ).map((v) => {
                    // groupData에서 해당 행의 값을 찾아 추가
                    const findParams = groupData.find(vf => vf.ISGroupName === v.IssueStageGroupDPK);
                    return {
                        ...v,
                        IssueStageGroupDPK: findParams?.ISGroupName || '',
                        isEditMode: false,

                    };
                });

                return [...existingRows, ...newRows].filter(
                    (row) => !deletedRowIds.includes(row.id)
                );
            });

            setHasUnsavedRows(false);
            unsavedChangesRef.current = {
                unsavedRows: {},
                rowsBeforeChange: {},
            };
        } catch (error) {
            setIsSaving(false);
        }
    }, [apiRef, groupData, stageData, projectCode]);

    const handleAddRow = React.useCallback(() => {
        const newId = randomId();
        const newRow = {
            id: newId,
            isNew: true,
            isEditMode: true
        };

        // 새로운 행을 맨 위에 추가하여 상태 업데이트
        setRows(prevRows => [newRow, ...prevRows]);

        // 새로운 행을 unsavedChangesRef에 추가
        unsavedChangesRef.current.unsavedRows[newRow.id] = newRow;
        setHasUnsavedRows(true);
        handleUserAction('addRow', newRow); // 로그 기록
    }, []);

    const handlerequest = () => {

        const isEqual = (obj1, obj2) => {
            const { id: id1, isNew: isNew1, isEditMode: isEditMode1, ...rest1 } = obj1;
            const { id: id2, isNew: isNew2, isEditMode: isEditMode2, ...rest2 } = obj2;
          
            const fieldsToCheck = ["DeliverableWV", "IssuePurposePD", "DeliverableNotxt", "ugenSubTitlSDT120", "IssuePurposePD"];
          
            for (const key in rest1) {
              if (key.includes("//") || fieldsToCheck.includes(key)) {
                if (key.includes("Start") || key.includes("Date")) {
                  const date1 = dayjs(rest1[key]).format("MM-DD-YYYY");
                  const date2 = dayjs(rest2[key]).format("MM-DD-YYYY");
                  if (date1 !== date2) {
                    return false;
                  }
                } else {
                  if (rest1[key] !== rest2[key]) {
                    return false;
                  }
                }
              }
            }
          
            return true;
          };
        const findOriginalRow = (record_no) => {
            return initialRows.find(row => row.record_no === record_no);
        };

        const updateData = rows
            .filter(v => v.record_no !== undefined) // record_no가 정의된 요소만 필터링
            .filter(row => {
                const originalRow = findOriginalRow(row.record_no);
                return originalRow && !isEqual(row, originalRow);
            }) // 필터링 조건 추가
            .map(row => {
                return Object.entries(row).reduce((newRow, [key, value]) => {
                    if (value instanceof Date) {
                        newRow[key] = dayjs(value).format("MM-DD-YYYY");
                    } else {
                        newRow[key] = value;
                    }
                    return newRow;
                }, {});
            });


        const transformedUpdateData = updateData.map(item => {
            const bpLineItems = {};
            const restItems = {};

            Object.entries(item).forEach(([key, value]) => {
                if (key.includes('//')) {
                    const [stage, newKey] = key.split('//');
                    if (!bpLineItems[stage]) {
                        bpLineItems[stage] = { IssueStage: stage };
                    }

                    // 새로운 키와 값을 해당 stage의 객체에 할당
                    bpLineItems[stage][newKey] = value;
                } else {
                    // "//"가 없는 항목은 그대로 restItems에 할당
                    restItems[key] = value;
                }
            });

            // 최종적으로 변환된 객체 반환
            return {
                ...restItems,
                _bp_lineitems: Object.values(bpLineItems).filter(v=>v.LineAutoSeq !== undefined)
            };
        })
            .map((v) => ({
                ...v, _bp_lineitems: v._bp_lineitems
                // .filter(c => c.EPPMPlanDate !== undefined && c.EPPMPlanDate !== null && c.EPPMPlanDate !== "")
            }));

        const createData = rows.filter(v => v.record_no === undefined)
            .map(row => {
                return Object.entries(row).reduce((newRow, [key, value]) => {
                    if (value instanceof Date) {
                        newRow[key] = dayjs(value).format("MM-DD-YYYY");
                    } else {
                        newRow[key] = value;
                    }
                    return newRow;
                }, {});

            });


        const transformedCreateData = createData.map(item => {
            const stages = groupData.find(vf => vf.ISGroupName === item.IssueStageGroupDPK)
            return {
                ...item,
                _bp_lineitems: stages._bp_lineitems.map((f) => ({
                    uuu_tab_id: "Issue Schedule",
                    IssueStage: f.IssueStageDPK,

                }))
            }
        })

        Swal.fire({
            title: '정말 Unifier으로 보내겠습니까?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: '네',
            cancelButtonText: '아니오'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post("/submitVP", { project: projectCode, updateData: transformedUpdateData, createData: transformedCreateData })
                    .then((response) => {
                        if (response.data.success) {
                            Swal.fire({
                                title: '성공적으로 제출되었습니다',
                                icon: 'success',
                                confirmButtonText: '확인'
                            }).then(() => {
                                // window.location.reload();
                            });
                        } else {
                            Swal.fire({
                                title: '에러 발생',
                                icon: 'error',
                                text: response.data.message,
                                confirmButtonText: '확인'
                            });
                        }
                    })
                    .catch((err) => {
                        Swal.fire({
                            title: '문제가 발생했습니다',
                            icon: 'error',
                            text: err
                        });
                    });
            }
        });

        handleUserAction('submitToUnifier', {
            project: projectCode,
            updateData: transformedUpdateData,
            createData: transformedCreateData,
        }); // 로그 기록
    };


    function EditToolbar() {

        const isEqual = (obj1, obj2) => {
            const { id: id1, isNew: isNew1, isEditMode: isEditMode1, ...rest1 } = obj1;
            const { id: id2, isNew: isNew2, isEditMode: isEditMode2, ...rest2 } = obj2;
          
            const fieldsToCheck = ["DeliverableWV", "IssuePurposePD", "DeliverableNotxt", "ugenSubTitlSDT120", "IssuePurposePD"];
          
            for (const key in rest1) {
              if (key.includes("//") || fieldsToCheck.includes(key)) {
                if (rest1[key] !== rest2[key]) {
                  return false;
                }
              }
            }
          
            return true;
          };


        const findOriginalRow = (record_no) => {
            return initialRows.find(row => row.record_no === record_no);
        };

        const updateData = rows
            .filter(v => v.record_no !== undefined) // record_no가 정의된 요소만 필터링
            .filter(row => {
                const originalRow = findOriginalRow(row.record_no);
                return originalRow && !isEqual(row, originalRow);
            })

        

        const isProjectApplyDisabled = React.useMemo(() => {
            const newRows = rows.filter((row) => row.id && !row.id.startsWith("PD-"));


            if ((updateData.length > 0 && !hasUnsavedRows) || (newRows.length > 0 && !hasUnsavedRows)) {
                return false;
            } else if (updateData.length === 0 || newRows.length === 0) {
                return true;
            } else if (hasUnsavedRows) {
                return true
            }

            return false;
        }, [rows, hasUnsavedRows, updateData]);

        return (
            <GridToolbarContainer>
                <Button color="primary" startIcon={<AddIcon />} onClick={handleAddRow}>
                    Add record
                </Button>

                <LoadingButton
                    disabled={!hasUnsavedRows}
                    loading={isSaving}
                    onClick={saveChanges}
                    startIcon={<SaveIcon />}
                    loadingPosition="start"
                >
                    <span>Save</span>
                </LoadingButton>
                <Button
                    disabled={!hasUnsavedRows || isSaving}
                    onClick={discardChanges}
                    startIcon={<RestoreIcon />}
                >
                    Discard all changes
                </Button>
                <Button
                    size="small"
                    onClick={toggleGroup}
                    style={{ marginRight: '10px' }}
                >
                    Toggle row expansion
                </Button>
                <GridToolbarQuickFilter style={{ marginRight: '10px' }} />

                <div style={{ display: 'flex', gap: '10px', alignItems: 'center', position: "absolute", right: 8 }}>
                    <ButtonGroup size="small" style={{ display: 'block' }} variant="outlined" aria-label="outlined button group">
                        <Button type='button' variant="text"
                            onClick={downloadExcel}
                            startIcon={<DownloadIcon />}>Export</Button>
                        <Button type='button' variant="text"
                            onClick={handleOpenFileUpload}
                            startIcon={<UploadIcon />}>Import
                            {/* <input type="file" accept=".csv, .xls, .xlsx" hidden ref={fileInputRef}
                            onChange={onAdd}
                            /> */}
                        </Button>
                    </ButtonGroup>



                    {/* <Button type='button' style={{ display: "block" }} variant="outlined" onClick={downloadExcel} size="small">전체 다운로드</Button> */}
                    <Button type='button' style={{ display: "block" }} variant="text"
                        onClick={handlerequest}
                        disabled={isProjectApplyDisabled}
                        size="small">PMAP 전송</Button>
                </div>
            </GridToolbarContainer>
        );
    }

    const getRowClassName = React.useCallback(({ id }) => {
        const unsavedRow = unsavedChangesRef.current.unsavedRows[id];
        if (unsavedRow) {
            if (unsavedRow._action === 'delete') {
                return 'row--removed';
            }
            return 'row--edited';
        }
        return '';
    }, []);

    //excel
    const downloadExcel = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');
        const validationSheet = workbook.addWorksheet('ValidationData');

        validationSheet.getColumn(1).values = ["PO Title", ...poData];


        const ActstaticFields = [
            { displayName: "PO Title", key: "POBPK", required: true },
            { displayName: "Deliverable No.", key: "DeliverableNotxt", required: true },
            { displayName: "Deliverable Title", key: "ugenSubTitlSDT120", required: true },
            { displayName: "Issue Stage Group", key: "IssueStageGroupDPK", required: true },

            { displayName: "Issue Purpose", key: "IssuePurposePD" },
            { displayName: "Weight Value", key: "DeliverableWV" },
            { displayName: "Issue Stage", key: "IssueStage" },
            { displayName: "Rev #", key: "usubRevNumberTB8" },
        ];

        // 동적 필드 정의
        const dynamicFieldNamesNormal = stageData.flatMap(v =>
            suffixes2.map(suffix => `${v}//${suffix}`)
        )
        // .filter(v => v.includes("Start"));

        const dynamicFieldNamesCode = stageData.flatMap(v =>
            suffixes.map(suffix => `${v}//${suffix}`)
        )
        // .filter(v => v.includes("Start"));

        const combinedFields = [...ActstaticFields.map(f => f.key), ...dynamicFieldNamesCode];
        // 상단 헤더 생성
        const topHeaders = ActstaticFields.map(f => "").concat(dynamicFieldNamesNormal.map(field => {
            const splitField = field.split("//");
            return splitField.length === 2 ? splitField[0] : field;
        }));


        // 하단 헤더 생성
        const bottomHeaders = ActstaticFields.map(f => f.required ? f.displayName + "*" : f.displayName)
            .concat(dynamicFieldNamesNormal.map(field => {
                const splitField = field.split("//");
                return splitField.length === 2 ? splitField[1] : field;
            }));

        worksheet.addRow(topHeaders);
        worksheet.addRow(bottomHeaders);
        const headerRow = worksheet.getRow(1);
        const headerRow2 = worksheet.getRow(2);

        headerRow.eachCell((cell) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFD3D3D3' } // 회색 배경
            };
            cell.font = {
                bold: true,
            };
        });
        headerRow2.eachCell((cell) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFD3D3D3' } // 회색 배경
            };
            cell.font = {
                bold: true,
            };
        });

        // 데이터 행 추가 및 날짜 형식 확인을 위한 함수
        const isDate = (value) => {
            if (typeof value === 'string' || value instanceof String) {
                const date = new Date(value);
                return !isNaN(date.getTime());
            }
            return value instanceof Date;
        };


        worksheet.columns.forEach(column => {
            let maxWidth = 10; // 최소 너비
            column.eachCell({ includeEmpty: true }, cell => {
                let cellLength = cell.value ? cell.value.toString().length : 0;
                if (cellLength > maxWidth) {
                    maxWidth = cellLength;
                }
            });
            column.width = maxWidth + 2;
        });


        // 스타일 적용
        ActstaticFields.forEach((field, index) => {
            const style = {
                font: { color: { argb: field.required ? 'FFFF0000' : 'FF000000' }, bold: true },
                fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFD3D3D3' } }
            };
            worksheet.getCell(2, index + 1).style = style;
        });

        let validationType = {
            type: 'list',
            formulae: ['=ValidationData!$A$2:$A$10'],
            showDropDown: true,
        };


        if (!rows.length) {

            for (let i = 2; i <= 5000; i++) {
                worksheet.getCell(`A${i}`).dataValidation = validationType;
            }


        } else {

            const dataRows = [...rows]
                .map(row => combinedFields.map(fieldName => {
                    const value = row[fieldName];
                    return value || ""; // 다른 필드는 빈 값인 경우 빈 문자열 반환
                }));

                const dateIndexes = [
                    8, 9, 10, 11, 15, 16, 17, 18, 22, 23, 24, 25, 29, 30, 31, 32,
                    36, 37, 38, 39, 43, 44, 45, 46, 50, 51, 52, 53, 57, 58, 59, 60,
                    64, 65, 66, 67, 71, 72, 73, 74, 78, 79, 80, 81, 85, 86, 87, 88,
                    92, 93, 94, 95, 99, 100, 101, 102, 106, 107, 108, 109, 113, 114, 115, 116,
                    120, 121, 122, 123, 127, 128, 129, 130, 134, 135, 136, 137, 141, 142, 143, 144,
                    148, 149, 150, 151, 155, 156, 157, 158, 162, 163, 164, 165, 169, 170, 171, 172,
                    176, 177, 178, 179, 183, 184, 185, 186, 190, 191, 192, 193, 197, 198, 199, 200,
                    204, 205, 206, 207, 211, 212, 213, 214, 218, 219, 220, 221, 225, 226, 227, 228,
                    232, 233, 234, 235, 239, 240, 241, 242, 246, 247, 248, 249, 253, 254, 255, 256,
                    260, 261, 262, 263, 267, 268, 269, 270, 274, 275, 276, 277, 281, 282, 283, 284,
                    288, 289, 290, 291, 295, 296, 297, 298
                  ];
                dataRows.forEach(row => {
                  row.forEach((cell, index) => {
                    if (isDate(cell) && dateIndexes.includes(index)) {
                      // 날짜 객체의 경우, 9시간을 더해준다 (KST로 조정)
                      let date = new Date(cell);
                      date.setHours(date.getHours() + 9);
                      row[index] = date;
                    }
                  });
                });


            dataRows.forEach((row, rowIndex) => {
                worksheet.addRow(row);

                // rowIndex는 0부터 시작하지만, ExcelJS에서는 1부터 시작하므로 1을 더해줍니다.
                let excelRowIndex = rowIndex + 3; // 헤더가 있으므로 2를 더합니다.

                row.forEach((cellValue, cellIndex) => {
                    worksheet.getCell(excelRowIndex, cellIndex + 1).value = cellValue;
                });
                // PMSClassNM 값을 확인
                const pmsClassNM = worksheet.getCell(`D${excelRowIndex}`).value; // 5번째 열 (E열)의 값을 가져옵니다.
                const filterValue = groupData.find(v => v.ISGroupName === pmsClassNM)?._bp_lineitems.map(v => v.IssueStageDPK);




                for (let colIndex = 9; colIndex <= bottomHeaders.length; colIndex++) {
                    let cell = worksheet.getCell(excelRowIndex, colIndex);
                    let creteriaCell = worksheet.getCell(1, colIndex).value;
                    let creteriaCell2 = worksheet.getCell(2, colIndex).value;

                    if (filterValue.length > 0 && !filterValue.includes(creteriaCell)) {
                        cell.style = {
                            fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'E0E0E0' } } // 페블색 배경
                        };
                    } else if (filterValue.length > 0 && filterValue.includes(creteriaCell) && (creteriaCell2.includes("Start"))
                        // &&bottomHeaders[colIndex].includes("Start")
                    ) {
                        cell.style = {
                            fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFADD8E6' } } // 파란색 배경
                        };
                    } else {
                        cell.style = {
                            fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'E0E0E0' } } // 페블색 배경
                        };

                    }
                }


                for (let colIndex = 1; colIndex <= 8; colIndex++) {
                    let cell = worksheet.getCell(excelRowIndex, colIndex);
                    let creteriaCell = worksheet.getCell(2, colIndex).value;
                    const noNeed = ["Vendor Name", "Discipline", "Issue Stage", "Rev #", "Issue Purpose"]
                    const Need = ["PO Title*", "Issue Stage Group*", "Deliverable No.*", "Deliverable Title*"]

                    if (filterValue.length > 0 && noNeed.includes(creteriaCell)) {
                        cell.style = {
                            fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'E0E0E0' } }
                        }
                    }
                    else if (filterValue.length > 0 && Need.includes(creteriaCell)) {
                        cell.style = {
                            fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFADD8E6' } } // 파란색 배경
                        };
                    }

                }
            });

            worksheet.views = [
                { state: 'frozen', ySplit: 2 }
            ];
            // 칼럼 너비 업데이트
            worksheet.columns.forEach((column, i) => {
                let maxWidth = 10; // 초기 최소 너비 설정

                // 헤더 행도 포함하여 너비 계산
                let headerLength = worksheet.getRow(1).getCell(i + 1).value
                    ? worksheet.getRow(1).getCell(i + 1).value.toString().length
                    : 0;
                if (headerLength > maxWidth) {
                    maxWidth = headerLength;
                }

                // 각 셀의 값에 따라 최대 너비 계산 (날짜 형식 제외)
                column.eachCell({ includeEmpty: true }, cell => {
                    if (!isDate(cell.value)) {
                        let cellLength = cell.value ? cell.value.toString().length : 0;
                        maxWidth = cellLength > maxWidth ? cellLength : maxWidth;
                    }
                });

                column.width = maxWidth + 2; // 약간의 여백 추가
            });


            for (let i = 2; i <= 5000; i++) {
                worksheet.getCell(`G${i}`).dataValidation = validationType;
            }


            // 값이 있는 모든 셀에 대해 테두리 설정
            worksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
                row.eachCell({ includeEmpty: false }, (cell, colNumber) => {
                    const border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' }
                    };
                    cell.border = border;
                });
            });

        }
        // 파일 저장
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        saveAs(blob, `Vendor Print 다운로드_${dayjs().format("YYYYMMDDHHmmss")}.xlsx`);

        handleUserAction('downloadExcel', {
            filename: `Vendor Print 다운로드_${dayjs().format("YYYYMMDDHHmmss")}.xlsx`,
        }); // 로그 기록

    };

    const onAdd = (event) => {
        try {

            if (!selectedFiles) {
                return;
            }

            setOpenFileUpload(false)

            const file = selectedFiles;

            const reader = new FileReader();
            reader.onloadend = (ev) => {
                if (!ev?.target?.result) {
                    return;
                }

                const wb = read(ev.target.result, { type: 'binary', cellText: false, cellDates: true });
                const sheets = wb.SheetNames;

                const headers = utils.sheet_to_json(wb.Sheets[sheets[0]], { header: 1, range: 0, blankrows: false }).slice(0, 2);
                // Combine the two header rows
                const combinedHeader = headers[0].map((topCell, index) => {
                    const bottomCell = headers[1][index];
                    const newIndex = _.findIndex(suffixes2, s => s === bottomCell);
                    const bottomCell2 = newIndex !== -1 ? suffixes[newIndex] : bottomCell;

                    // 필요한 경우, topCell과 bottomCell2에서 별표(*) 제거
                    const cleanTopCell = topCell ? topCell.replace(/\*/g, '') : topCell;
                    const cleanBottomCell2 = bottomCell2.replace(/\*/g, '');

                    return cleanTopCell ? `${cleanTopCell}//${cleanBottomCell2}` : cleanBottomCell2;
                });



                const originColumns = columns.map(v => v.field).filter(v => v !== "actions" && v !== "id" && v !== "selectIssueGroup");

                const headerKeyMapping = combinedHeader.reduce((acc, headerName, index) => {
                    const staticField = staticFields.find(field => field.displayName === headerName);
                    if (staticField) {
                        acc[headerName] = staticField.key;
                    } else {
                        // For headers with '//', use the headerName itself as key
                        acc[headerName] = headerName;
                    }
                    return acc;
                }, {});

                const headerValues = Object.values(headerKeyMapping).filter(v => v !== "id");


                if (sheets.length) {
                    if (headerValues.every(function (value, index) { return value === originColumns[index] })) {

                        // 모든 데이터를 읽기 (헤더 포함)
                        const allData = utils.sheet_to_json(wb.Sheets[sheets[0]], { header: 1, blankrows: false });

                        // 첫 번째와 두 번째 행을 추출
                        const firstRow = allData[0].map((topCell) => (topCell ? topCell.replace(/\*/g, '') : topCell));
                        const secondRow = allData[1].map(v => {
                            const index = _.findIndex(suffixes2, s => s === v);
                            return index !== -1 ? suffixes[index] : v; // 일치하는 요소가 없을 경우 원래 값을 반환
                        }).map((Cell) => (Cell ? Cell.replace(/\*/g, '') : Cell));


                        // 결합된 헤더 생성
                        const combinedHeaderExcel = firstRow.map((cell, index) => {
                            if (cell !== "") {
                                return cell + '//' + secondRow[index];
                            }
                            return staticFields.find(v => v.displayName === secondRow[index]).key;
                        });


                        // 실제 데이터 행들만 추출 (첫 번째와 두 번째 행 제외)
                        const dataRows = allData.slice(2);

                        // 결합된 헤더를 사용하여 각 행의 데이터 추출 및 변환
                        const excelrows = dataRows.map(row => {
                            return combinedHeaderExcel.reduce((newRow, header, index) => {
                                newRow[header] = row[index];
                                return newRow;
                            }, {});
                        })

                        const isValidDate = (dateString) => {
                            const date = new Date(dateString);
                            return !isNaN(date.getTime());
                        };


                        excelrows.forEach(row => {
                            Object.keys(row).forEach(key => {
                                if (key.includes("Start") || key.includes("ApprovedDate")) {
                                    if (row[key]) {
                                        row[key] = parseExcelDateWithOneMinuteAdjustment(row[key]);
                                    }
                                }
                            });
                        });


                        const errorMessages = [];

                        ///엑셀 Validataion시작
                        const allowRecord = rows.filter(v => v.DeliverableNotxt !== undefined && v.DeliverableNotxt !== null).map((v => v.DeliverableNotxt));
                        const requiredColumns = ["DeliverableNotxt", "ugenSubTitlSDT120", "POBPK", "IssueStageGroupDPK"];
                        const convertedColumns = ["Deliverable No", "Deliverable Title", "PO Title", "Issue Stage Group"];


                        // Check each row for valid values
                        for (let i = 0; i < excelrows.length; i++) {
                            const row = excelrows[i];
                            let errorsForRow = [];

                            requiredColumns.forEach((col, index) => {
                                if (!row[col] && row[col] !== 0) {
                                    errorsForRow.push(`'${convertedColumns[index]}' 필드가 누락되었습니다.`);
                                }
                            });

                            // 날짜 필드에 대한 유효성 검사
                            const dateFields = columnsDate.map(v => v.field).filter(v => v.includes("Start") || v.includes("ApprovedDate"));

                            for (let j = 4; j < dateFields.length; j++) {
                                if (row[dateFields[j]] && !isValidDate(row[dateFields[j]])) {
                                    errorsForRow.push(`'${dateFields[j]}' 날짜가 유효하지 않습니다.`);
                                }
                                if (j > 0 && !DatesOrderValid(row[dateFields[j - 4]], row[dateFields[j]])) {
                                    errorsForRow.push(`'${dateFields[j]}' 날짜 순서가 올바르지 않습니다.`);
                                }
                            }

                            // DeliverableNotxt 값 검증
                            const { DeliverableNotxt } = row;

                            // DeliverableNotxt 값 유일성 검증
                            const allRows = [...excelrows.slice(0, i)];
                            const deliverableNotxtValues = allRows.map((r) => r.DeliverableNotxt);
                            if (deliverableNotxtValues.includes(DeliverableNotxt)) {
                                errorsForRow.push(`Duplicate Deliverable No.: ${DeliverableNotxt}`);
                            }

                            if (errorsForRow.length > 0) {
                                errorMessages.push({ row: i, errors: errorsForRow });
                            }
                        }
                        if (errorMessages.length > 0) {

                            // Dynamic fields from columnsDate
                            const dynamicFieldNames = stageData.flatMap(v =>
                                suffixes2.map(suffix => `${v}//${suffix}`)
                            );

                            const dynamicFieldNamesCode = stageData.flatMap(v =>
                                suffixes.map(suffix => `${v}//${suffix}`)
                            );
                            // Combine static and dynamic fields
                            const combinedFields = [...staticFields.map(f => f.key), ...dynamicFieldNamesCode, "error"];


                            // Create two arrays for the two header rows
                            const topHeaders = staticFields.map(f => "").concat(dynamicFieldNames.map(field => {
                                const splitField = field.split("//");
                                return splitField.length === 2 ? splitField[0] : "";
                            }));

                            const bottomHeaders = staticFields.map(f => f.displayName).concat(dynamicFieldNames.map(field => {
                                const splitField = field.split("//");
                                return splitField.length === 2 ? splitField[1] : field;
                            }));

                            // Create the worksheet with headers
                            const worksheet = utils.aoa_to_sheet([topHeaders, bottomHeaders]);

                            // Find and merge cells with the same value in the top header
                            let currentMerge = null;
                            topHeaders.forEach((header, index) => {
                                if (header !== "" && (index === 0 || topHeaders[index - 1] !== header)) {
                                    currentMerge = { s: { r: 0, c: index }, e: { r: 0, c: index } };
                                }
                                if (header !== "" && (index === topHeaders.length - 1 || topHeaders[index + 1] !== header)) {
                                    if (currentMerge) {
                                        currentMerge.e.c = index;
                                        if (!worksheet["!merges"]) worksheet["!merges"] = [];
                                        worksheet["!merges"].push(currentMerge);
                                    }
                                }
                            });

                            excelrows.forEach((row, index) => {
                                const errorForRow = errorMessages.find(e => e.row === index);
                                if (errorForRow) {
                                    row['error'] = errorForRow.errors.join('; ');
                                }
                            });


                            // Append your data rows
                            const dataRows = excelrows.map(row => combinedFields.map(fieldName => row[fieldName] || ""));
                            utils.sheet_add_json(worksheet, dataRows, { origin: -1, skipHeader: true });

                            const workbook = utils.book_new();
                            utils.book_append_sheet(workbook, worksheet, "Sheet1");
                            writeFile(workbook, `임포트 오류_${dayjs().format("YYYYMMDDHHmmss")}.xlsx`);


                            // 오류 알림
                            Swal.fire({
                                icon: 'error',
                                title: '오류가 발견되었습니다. 다시 다운로드된 엑셀 파일을 확인하세요.',
                                confirmButtonText: '확인',
                                customClass: {
                                    title: 'swal-title',
                                    content: 'swal-text',
                                    confirmButton: 'swal-button'
                                }
                            }).then((result) => {
                                if (result.value) {
                                    // 사용자가 '확인' 버튼을 클릭하면 페이지를 새로고침
                                    window.location.reload();
                                }
                            });

                        } else {

                            const add_row = _.differenceBy(excelrows, rows, 'DeliverableNotxt').map((v) => ({
                                ...v,
                                id: randomId()
                            }))
                            const exist = _.differenceBy(excelrows, add_row, 'DeliverableNotxt').map((v) => ({
                                ...v,
                                id: rows.find(vf => vf.DeliverableNotxt === v.DeliverableNotxt).id
                            }));

                            const newDataState = [...exist, ...add_row];


                            setRows(newDataState
                                .filter((v) => {
                                  return Object.keys(v).every(key => {
                                    return !key.includes("TOCLVTRNoDPK") || (v[key] === null || v[key] === ""||v[key] === undefined);
                                  });
                                })
                                .map((v) => {
                                  const initialRow = initialRows.find(vf => vf.DeliverableNotxt === v.DeliverableNotxt);
                                  if (initialRow) {
                                    const additionalFields = Object.entries(initialRow)
                                      .filter(([key]) => key.includes("//LineAutoSeq"))
                                      .reduce((acc, [key, value]) => {
                                        acc[key] = value;
                                        return acc;
                                      }, {});
                                    
                                    // 키 값이 빈 문자열인 경우 null로 변경
                                    const updatedV = Object.entries(v).reduce((acc, [key, value]) => {
                                      acc[key] = value === "" ? null : value;
                                      return acc;
                                    }, {});
                              
                                    return { ...updatedV, record_no: initialRow.record_no, ...additionalFields };
                                  }
                                  return v;
                                })
                              );

                            setHasUnsavedRows(false)

                            Swal.fire({
                                icon: 'success',
                                title: '엑셀을 테이블로 로드했습니다. 데이터에 문제가 없으면 프로젝트 적용 버튼을 클릭하세요',
                                confirmButtonText: '확인',
                                customClass: {
                                    title: 'swal-title',
                                    content: 'swal-text',
                                    confirmButton: 'swal-button'
                                }
                            });
                        }
                    }

                    else {
                        Swal.fire({
                            icon: 'error',
                            title: '임포트한 엑셀 헤더를 확인하세요. 다운로드에 있는 헤더를 사용해야합니다.',
                            confirmButtonText: '확인',
                            customClass: {
                                title: 'swal-title',
                                content: 'swal-text',
                                confirmButton: 'swal-button'
                            }
                        });
                    }
                }

            };
            reader.readAsArrayBuffer(file);
        } catch (error) {
            // 에러 발생 시 Swal 팝업 띄우기
            Swal.fire({
                icon: 'error',
                title: '오류가 발생했습니다!',
                text: error.message,
                confirmButtonText: '확인',
                customClass: {
                    title: 'swal-title',
                    content: 'swal-text',
                    confirmButton: 'swal-button'
                }
            });
        }

        handleUserAction('uploadExcel', {
            filename: selectedFiles.name,
        }); // 로그 기록

    };

    const [pinnedColumns, setPinnedColumns] = React.useState({});

    const handlePinnedColumnsChange = React.useCallback((updatedPinnedColumns) => {
        setPinnedColumns(updatedPinnedColumns);
    }, []);

    useEffect(() => {
        if (rows.length > 0 && apiRef.current) {
            const pinnedColumns = [

            ];

            const groups = apiRef.current.getRowNode(GRID_ROOT_GROUP_ID).children;
            const isGrouped = groups.length > 0;


            setPinnedColumns({
                left: isGrouped ? pinnedColumns : [],
            });
        }
    }, [rows, apiRef]);


    return (<>

        <Box
            sx={{
                height: 'calc(100vh - 120px)',
                backgroundColor: '#fff',
                borderRadius: "14px",
                width: '100%',
                "& .MuiDataGrid-root": {
                    border: 0
                },
                "& .MuiDataGrid-row.Mui-hovered": {
                    backgroundColor: "transparent",
                },
                // Take out the hover colour
                "& .MuiDataGrid-row:hover": {
                    backgroundColor: "transparent",
                },
                '& .MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 'bold', // 헤더 셀의 글자를 진하게 설정
                },
                '& .MuiDataGrid-cell': {
                    fontSize: 12,
                },
                '& .notmatched': {
                    backgroundColor: '#f2f3f3',
                },
                '& .matched': {
                    backgroundColor: 'rgba(255, 254, 176, 0.3)!important',
                },
                '& .groupRow': {
                    backgroundColor: '#fff',
                },

                // '& .notmatched.MuiDataGrid-cell--editing': {
                //     backgroundColor: '#f2f3f3',
                //     color: '#f2f3f3'
                // },
                '& .notmatched input': {
                    // backgroundColor: '#F0EDE5', 
                    fontSize: 0
                },
                '& .notmatched.MuiDataGrid-cell': {
                    backgroundColor: '#f2f3f3',
                },
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
            }}
        >

            <DataGridPremium
                apiRef={apiRef}
                editMode="cell"
                rows={rows}
                rowHeight={38}
                initialState={initialState}

                pinnedColumns={pinnedColumns}
                onPinnedColumnsChange={handlePinnedColumnsChange}

                columns={columns}
                cellSelection
                pagination
                // pageSize={10} // 초기 페이지 크기 설정
                // rowsPerPageOptions={[10, 20, 50]} // 선택 가능한 페이지 크기 옵션
                autoPageSize
                defaultGroupingExpansionDepth={-1}
                // checkboxSelection
                disableRowSelectionOnClick
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    setRowSelectionModel(newRowSelectionModel);
                }}
                rowSelectionModel={rowSelectionModel}
                density="compact"
                processRowUpdate={processRowUpdate}
                slots={{ toolbar: EditToolbar }}
                experimentalFeatures={{ columnGrouping: true }}
                columnGroupingModel={columnGroupingModel}
                isCellEditable={(params) => {
                    // 새로운 행인 경우 편집 가능
                    if (params.row.isNew) {
                        return true;
                    }

                    // id가 "PD-"로 시작하고 usubRevNumberTB8가 "-"인 경우에만 편집 가능
                    if (params.row.id.startsWith("VPL") && params.row.usubRevNumberTB8 === "-") {
                        return true;
                    }

                    // 그 외의 경우는 편집 불가능
                    return false;
                }}
                groupingColDef={{
                    headerName: 'Group',

                }}

                sx={{

                    '& .row--removed:hover': {
                        backgroundColor: "transparent",
                    },
                    [`& .${gridClasses.columnHeader} .MuiDataGrid-columnHeaderTitle`]: {
                        fontSize: '11px', // 헤더 셀의 글자 크기 변경
                    },
                    [`& .MuiDataGrid-cell`]: {
                        fontSize: '10px', // 행의 글자 크기 변경  
                    },

                    [`& .${gridClasses.row}.row--removed`]: {
                        backgroundColor: (theme) => {
                            if (theme.palette.mode === 'light') {
                                return 'rgba(255, 170, 170, 0.3)!important';
                            }
                            return darken('rgba(255, 170, 170, 1)', 0.7);
                        },
                        '&:hover': {
                            backgroundColor: (theme) => {
                                if (theme.palette.mode === 'light') {
                                    return 'rgba(255, 170, 170, 0.3)';
                                }
                                return darken('rgba(255, 170, 170, 1)', 0.7);
                            }
                        },
                    },

                }}
                loading={isSaving}
                getRowClassName={getRowClassName}

            />
            {!!snackbar && (
                <Snackbar
                    open
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    onClose={handleCloseSnackbar}
                // autoHideDuration={6000}
                >
                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                </Snackbar>
            )}
        </Box>





        {openFileUpload && (
            <Dialog open={Boolean(openFileUpload)} onClose={handleCloseFileUpload}
                PaperProps={{
                    style: {
                        padding: '8px', // 여기서 패딩 조정
                    },
                }}>
                <DialogTitle>파일 업로드</DialogTitle>
                <DialogContent sx={{ padding: '8px', widhth: "400px" }}>
                    <div {...getRootProps()} style={{ ...dropzoneStyle, ...(isDragActive ? activeStyle : {}) }}>
                        <input {...getInputProps()} />
                        {
                            isDragActive ?
                                <p>Drop the files here ...</p> :
                                <p>여기로 드래그 & 드랍을 하거나, 파일을 고르기 위해 여기를 클릭하세요. 하나의 xlsx만 허용됩니다.</p>
                        }
                    </div>
                    {selectedFiles && (
                        <p style={{ paddingLeft: 10, fontSize: 11 }}>
                            {selectedFiles.path} - {selectedFiles.size} bytes&nbsp;
                            <IconButton sx={{ p: 0 }} onClick={handleRemoveFile}> <HighlightOffIcon sx={{ fontSize: 16 }} /> </IconButton>
                        </p>

                    )}

                    {error && (
                        <p style={{ color: 'red', paddingLeft: 10, fontSize: 11 }}>{error}</p>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseFileUpload}>취소</Button>
                    <Button disabled={error !== null || !selectedFiles || selectedFiles.length === 0} onClick={onAdd}>업로드</Button>
                </DialogActions>
            </Dialog>


        )}
        <Dialog open={openGroupDataModal} onClose={handleCloseGroupDataModal} maxWidth="md" fullWidth>
            <DialogTitle>Select Issuse Stage Group</DialogTitle>
            <DialogContent>
                <div style={{ height: 400, width: '100%' }}>
                    <DataGridPremium
                        density="compact"
                        rows={groupData}
                        pagination
                        autoPageSize
                        columns={[
                            // { field: 'id', headerName: 'id', width: 150 },
                            { field: 'ISGroupName', headerName: 'Issue Stage Group Name', width: 250 },
                            { field: 'IssuePurposePD', headerName: 'Issue Purpose', width: 200 },
                            { field: 'description', headerName: 'Description', width: 200 },
                        ]}
                        onRowClick={(params) => handleSelectGroupData(params.row)}
                        slots={{
                            toolbar: () => (
                                <GridToolbarContainer>
                                    <GridToolbarQuickFilter sx={{ width: '100%' }} />
                                </GridToolbarContainer>
                            ),
                        }}
                        getDetailPanelContent={(params) => {
                            const row = params.row;
                            return (
                                <div style={{ padding: 10 }}>
                                    <strong>Issue Stages:</strong>
                                    <ul>
                                        {row._bp_lineitems.map((item, index) => (
                                            <li key={index}>{item.IssueStageDPK}</li>
                                        ))}
                                    </ul>
                                </div>
                            );
                        }}
                        getDetailPanelHeight={() => 'auto'}
                        sx={{
                            [`& .${gridClasses.row}.Mui-expanded`]: {
                                backgroundColor: (theme) =>
                                    theme.palette.mode === 'light' ? '#f5f5f5' : '#333',
                            },
                        }}
                    />
                </div>
            </DialogContent>
        </Dialog>
    </>



    )

}

export default VPtable
