import React, { useState } from 'react';
import { Box, Button, Card, CircularProgress, FormLabel, FormControl, Link, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import axios from "../common/axios";
import Swal from 'sweetalert2';

import ForgotPassword from './ForgotPassword';
import { SitemarkIcon } from './CustomIcons';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.lgchem.com">
        LG화학
      </Link>
      {' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const CardStyled = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  boxShadow: 'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
  [theme.breakpoints.up('sm')]: {
    width: '450px',
  },
}));

export default function SignInCard() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (passwordError) {
      return;
    }
    setIsLoading(true);
    try {
      const response = await axios.post('/lgclogin', { username, password });
      setIsLoading(false);
      if (response.data.success) {
        const redirectPath = response.data.redirectPath;
        navigate(redirectPath, { replace: true });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Login Failed',
          text: response.data.message,
        });
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Login error:', error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An error occurred during login. Please try again later.',
      });
    }
  };
  return (
    <CardStyled variant="outlined">
      <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
        <SitemarkIcon />
      </Box>
      <Typography component="h1" variant="h4" sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}>
        Sign in
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 2 }}>
        <FormControl>
          <FormLabel sx={{ fontWeight: 'bold' }}>User Name</FormLabel>
          <TextField
            fullWidth
            id="username"
            name="username"
            autoFocus
            required
            variant="outlined"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <FormLabel htmlFor="password" sx={{ fontWeight: 'bold' }}>Password</FormLabel>
            <Link component="button"   type="button" onClick={() => setOpen(true)} variant="body2" sx={{ alignSelf: 'baseline' }}>
              Forgot your password?
            </Link>
          </Box>
          <TextField
            error={passwordError}
            helperText={passwordErrorMessage}
            name="password"
            placeholder="••••••••"
            type="password"
            id="password"
            autoComplete="current-password"
            // autoFocus
            required
            fullWidth
            variant="outlined"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              if (e.target.value.length < 8) {
                setPasswordError(true);
                setPasswordErrorMessage('Password must be at least 8 characters long.');
              } else {
                setPasswordError(false);
                setPasswordErrorMessage('');
              }
            }}
          />
        </FormControl>
        <ForgotPassword open={open} handleClose={() => setOpen(false)} />
        <Button type="submit" fullWidth variant="contained" disabled={!username || !password || password.length < 8 || isLoading}>
        {isLoading ? <CircularProgress size={24} /> : 'Sign in'}
      </Button>
        <Typography sx={{ textAlign: 'center' }}>
          Don't have an account?{' '}
          <span>
            <Link href="/signup" variant="body2" sx={{ alignSelf: 'center' }}>
              Sign up
            </Link>
          </span>
        </Typography>
      </Box>
      <Copyright sx={{ mt: 5 }} />
    </CardStyled>
  );
}