import React from 'react';

const date = new Date();
const year = date.getFullYear();

const Footer = () => {
    return (
        <div style={{  padding: '6px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {/* <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', color: '#343a40' }}> */}
                <span style={{ color: '#6c757d', fontWeight: '600', marginRight: '8px' }}>{year}©</span>
                <a href="https://www.lgchem.com/" target="_blank" style={{ color: '#343a40', textDecoration: 'none', fontWeight: 'bold', ':hover': { color: '#0d6efd' } }}>LG 화학</a>
            {/* </div> */}
        </div>
    );
}

export default Footer;
