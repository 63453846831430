import { Navigate, Outlet } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Circles } from 'react-loader-spinner';
import axios from "./axios";

const PrivateRoute = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkAuthentication = async () => {
      try {

          // 인증 상태 확인 API 호출
          const response = await axios.get('/is_logged_in');
          if (response.data.isLoggedIn) {
            setIsAuthenticated(true);
          }
      } catch (error) {
        console.error('인증 상태 확인 중 오류 발생:', error);
      }

      setIsLoading(false);
    };

    checkAuthentication();
  }, []);

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Circles color="#00BFFF" height={80} width={80} />
      </div>
    );
  }

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" replace state={{ redirect: window.location.pathname }} />;
};

export default PrivateRoute;