import { atom } from 'recoil';

export const selectedProjectState = atom({
  key: 'selectedProjectState',
  default: null,
});

export const selectedMenuLabelState = atom({
  key: 'selectedMenuLabelState', // unique ID (with respect to other atoms/selectors)
  default: '', // default value (aka initial value)
});