import React, { useEffect, useState, useRef, useCallback } from 'react';
import axios from "../common/axios";
import { Circles } from 'react-loader-spinner';
import { useRecoilValue } from 'recoil';
import { selectedProjectState } from '../recoil/atoms';
import VPtable from './vpTable';

const VPpage = () => {
  const selectedProject = useRecoilValue(selectedProjectState);
  // const selectedProject = {projectCode:"P-0001", projectName:"LG Deliverable"};
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [stageData, setStageData] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [poData, setPOData] = useState([]);


  const fetchData = useCallback(async () => {
    if (selectedProject) {
      const res = await axios.get(`/getVP?path=${selectedProject.projectCode}`);
      const res2 = await axios.get(`/getVPStage`);
      const res3 = await axios.get(`/getVPGroup?path=${selectedProject.projectCode}`);
      const res4 = await axios.get(`/getPO?path=${selectedProject.projectCode}`);
      if (res.data.success && res2.data.success && res3.data.success) {
          const drawinData = res.data.data.length > 0 ? res.data.data.map((v) => ({
            ...v,
            id: v.record_no,
            _bp_lineitems: v._bp_lineitems ? v._bp_lineitems.map((vf) => ({
              ...vf,
              IssuePlanStart: vf.IssuePlanStart ? new Date(vf.IssuePlanStart.slice(0, 10)) : null,
              IssueForecastStart: vf.IssueForecastStart ? new Date(vf.IssueForecastStart.slice(0, 10)) : null,
              IssueActualStart: vf.IssueActualStart ? new Date(vf.IssueActualStart.slice(0, 10)) : null,
              ApprovedDate: vf.ApprovedDate ? new Date(vf.ApprovedDate.slice(0, 10)) : null,
            })) : [] 
          })) : [];

          setPOData(res4.data.data)

          const stage = res2.data.data;
          setStageData(stage);

          const group = res3.data.data.map((v, index) => ({ ...v, id: index }));
          setGroupData(group);

          const newDrawingData = drawinData.map(drawingItem => {
            const newDrawingItem = { ...drawingItem };
            stage.forEach(stage => {
              newDrawingItem[stage] = null;
            });
            return newDrawingItem;
          });

          const updatedDrawingData = newDrawingData.map(drawingItem => {
            const updatedDrawingItem = { ...drawingItem };
            if (Array.isArray(drawingItem._bp_lineitems)) {
              stage.forEach(stage => {
                const stageItem = drawingItem._bp_lineitems.find(item => item.IssueStage === stage);
                updatedDrawingItem[stage] = stageItem || null;
              });
            } else {
              stage.forEach(stage => {
                updatedDrawingItem[stage] = null;
              });
            }
            return updatedDrawingItem;
          });

          const flattenDrawingData = updatedDrawingData.map(drawingItem => {
            const flattenedItem = {};
            Object.entries(drawingItem).forEach(([key, value]) => {
              if (value && typeof value === 'object' && !Array.isArray(value)) {
                Object.entries(value).forEach(([innerKey, innerValue]) => {
                  flattenedItem[`${key}//${innerKey}`] = innerValue;
                });
              } else {
                flattenedItem[key] = value;
              }
            });
            return flattenedItem;
          });

          const lastData = flattenDrawingData.map((v) => {
            delete v._bp_lineitems;
            return { ...v, isNew: false, isEditMode: false };
          });

          setTableData(lastData);
          setLoading(false);
        }
      }
    }, [selectedProject]);

    useEffect(() => {
      setLoading(true);
      fetchData();
    }, [fetchData]);
  

  return (
    <>
      {loading ? (
        <div className="loadingContainer">
          <Circles ariaLabel="loading" color="#00BFFF" height={100} width={100} />
        </div>
      ) : (
        groupData.length > 0 && stageData.length > 0 ? (
          <VPtable
            projectCode={selectedProject.projectCode}
            stageData={stageData}
            initialRows={tableData}
            newkey={'record_no'}
            groupData={groupData}
            poData={poData}
          />
        ) : (
          <div style={{
            margin: '16px',
            padding: '24px',
            background: '#f8f9fa',
            borderRadius: '8px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
          }}>
            <h3 style={{ margin: '0 0 16px', color: '#343a40' }}>도서 그룹 및 스테이지 셋팅 안내</h3>
            <p style={{ margin: '0 0 8px', fontSize: '16px', lineHeight: '1.6', color: '#495057' }}>
              현재 프로젝트의 도서 그룹 및 스테이지 셋팅이 완료되지 않았습니다.
            </p>
            <p style={{ margin: '0 0 16px', fontSize: '16px', lineHeight: '1.6', color: '#495057' }}>
              셋팅이 완료되어야 MDL 페이지를 정상적으로 사용할 수 있습니다.
            </p>
            <p style={{ margin: '0', fontSize: '16px', lineHeight: '1.6', color: '#495057' }}>
              셋팅요청을 위해서는 LG화학 담당자에게 연락하시기 바랍니다.
            </p>
            <div style={{ marginTop: '24px', textAlign: 'right' }}>
              <a
                href="mailto:lgchem@example.com"
                style={{
                  display: 'inline-block',
                  padding: '12px 24px',
                  background: '#1a73e8',
                  color: 'white',
                  textDecoration: 'none',
                  borderRadius: '4px',
                  fontSize: '16px',
                  fontWeight: 'bold'
                }}
              >
                LG화학 담당자에게 메일 보내기
              </a>
            </div>
          </div>
        )
      )}
    </>
  );
};

export default VPpage;