import axios from '../common/axios';


const logout = async () => {
  try {
    // 서버에 로그아웃 요청
    await axios.get(`/logout`);

    // 로그인 페이지로 리다이렉트
    window.location = '/login';
  } catch (error) {
    console.error("Error during logout:", error);
  }
};

export default logout;
