import React, { useEffect, useState, useRef, useCallback } from 'react';
import axios from "../common/axios"
import { Circles } from 'react-loader-spinner';
import { useRecoilValue } from 'recoil';
import { selectedProjectState } from '../recoil/atoms';
import TRTable from "./trTable";
import Layout from '../layout/layout';

const TRPage = () => {
    const selectedProject = useRecoilValue(selectedProjectState);
    const [loading, setLoading] = useState(true);
    const [tableData, setTableData] = useState([]);
    const [drawingData, setDrawingData] = useState([]);
    const [issueStage, setIssueStage] = useState([]);
    const [revData, setRevData] = useState([]);
    const [Discipline, setDiscipline] = useState([]);


    const fetchData = useCallback(async () => {
        if (selectedProject) {
            const res = await axios.get(`/getTR?path=${selectedProject.projectCode}`);
            const res2 = await axios.get(`/getMDL?path=${selectedProject.projectCode}`);
            const res3 = await axios.get(`/getIssueStage?path=${selectedProject.projectCode}`);
            const res4 = await axios.get(`/getRev?path=${selectedProject.projectCode}`);
            const res5 = await axios.get(`/getdiscipline?path=${selectedProject.projectCode}`);
            if (res.data.success && res2.data.success && res3.data.success) {
                    const trData = res.data.data.length > 0 ? res.data.data.map((v) => ({
                        ...v,
                        id: v.record_no,
                        TRIssueDate: v.TRIssueDate ? new Date(v.TRIssueDate.slice(0, 10)) : null,
                        ugenDueDateDO: v.ugenDueDateDO ? new Date(v.ugenDueDateDO.slice(0, 10)) : null,
                    })) : [];

                    const transformedData = trData.map(item => {
                        const {
                            CRMAssignedEngLogNM,
                            CRMDisciplineBPK,
                            CRMPMUNM,
                            ContractorCode,
                            DisciplineCodeTxT,
                            TRDesc,
                            TRIssueDate,
                            TRNo,
                            TRTitle,
                            record_no,
                            status,
                            ugenDueDateDO,
                            uuu_creation_date,
                            _bp_lineitems,
                        } = item;

                        return _bp_lineitems.map(lineItem => ({
                            DeliverableNotxt: lineItem.ugenSubRecNoPK,
                            DocKey: lineItem.DocKey,
                            DocKeyTxt: lineItem.DocKeyTxt,
                            IssueStage: lineItem.IssueStage,
                            ugenSubRecNoPK: lineItem.ugenSubRecNoPK,
                            ugenSubTitlSDT120: lineItem.ugenSubTitlSDT120,
                            usubRevNumberTB8: lineItem.usubRevNumberTB8,
                            CRMAssignedEngLogNM,
                            CRMDisciplineBPK,
                            CRMPMUNM,
                            ContractorCode,
                            DisciplineCodeTxT,
                            TRDesc,
                            TRIssueDate,
                            TRNo,
                            TRTitle,
                            record_no,
                            status,
                            ugenDueDateDO,
                            uuu_creation_date,
                        }));
                    }).flat();

                    setTableData(transformedData);
                    setDrawingData(res2.data.data);
                    setIssueStage(res3.data.data);
                    setRevData(res4.data.data);
                    setDiscipline(res5.data.data);
                    setLoading(false);
                }
            }
        }, [selectedProject]);
    
        useEffect(() => {
            setLoading(true);
            fetchData();
        }, [fetchData]);

    return (
        <>
            {loading ? (
                <div className="loadingContainer">
                    <Circles ariaLabel="loading" color="#00BFFF" height={100} width={100} />
                </div>
            ) : (
                <TRTable
                    projectCode={selectedProject.projectCode}
                    initialRows={tableData}
                    drawingData={drawingData}
                    Discipline={Discipline}
                    revData={revData}
                    issueStage={issueStage}
                />
            )}
        </>
    );
};

export default TRPage;