import React, { useState, useEffect, useRef } from 'react';
import axios from '../common/axios';
import logout from '../common/logout';
import { useRecoilState, useRecoilValue } from 'recoil';
import { selectedProjectState, selectedMenuLabelState } from '../recoil/atoms';
import { Select, MenuItem, FormControl, Avatar, Typography, Box, Chip, Divider } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const Header = () => {
    const [isUserDropdownVisible, setUserDropdownVisible] = useState(false);
    const [userName, setUserName] = useState('User Name');
    const [projectList, setProjectList] = useState([]);
    const [companyName, setCompanyName] = useState("");
    const [LogInTime, setLogInTime] = useState("");
    const [selectedProject, setSelectedProject] = useRecoilState(selectedProjectState);
    const selectedMenuLabel = useRecoilValue(selectedMenuLabelState); // Get the menu label from Recoil

    const userDropdownRef = useRef(null);
    const userInitial = userName.charAt(0);

    const toggleUserDropdown = () => {
        setUserDropdownVisible(!isUserDropdownVisible);
    };

    const handleLogoutClick = () => {
        setUserDropdownVisible(false);
        logout();
    };

    const handleProjectChange = (event) => {
        const projectCode = event.target.value;
        const project = projectList.find((p) => p.projectCode === projectCode);
        setSelectedProject(project);
    };

    useEffect(() => {
        const fetchData = async () => {
            const res = await axios.get(`/getuser`);
            setUserName(res.data.username)
            setProjectList(res.data.projectList);
            setSelectedProject(res.data.projectList[0]);
            setCompanyName(res.data.companyName);
            setLogInTime(res.data.logInTime);
        }
        fetchData()
    }, [])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (userDropdownRef.current && !userDropdownRef.current.contains(event.target)) {
                setUserDropdownVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [userDropdownRef]);

    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 100,
            backgroundColor: '#ffffff',
            boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
            padding: '10px 20px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <a href="https://www.lgchem.com/main/index">
                    <img alt="Logo" src="/logo_basic.svg" style={{ height: '30px' }} />
                </a>
                {selectedMenuLabel && (
                    <Typography variant="h6" sx={{ ml: 3, fontWeight: 'medium' }}>
                        {selectedMenuLabel}
                    </Typography>
                )}
            </Box>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <FormControl size='small'>
                    <Select

                        value={selectedProject?.projectCode || ''}
                        onChange={handleProjectChange}
                        style={{ marginRight: '20px', fontSize: "0.8rem" }}
                    >
                        {projectList.map((project) => (
                            <MenuItem sx={{ fontSize: '0.8rem' }} key={project.projectCode} value={project.projectCode}>
                                {project.projectName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <div ref={userDropdownRef} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={toggleUserDropdown}>
                    <Avatar sx={{ width: 28, height: 28 }}>{userInitial}</Avatar>
                    <Typography variant="subtitle1" style={{ marginLeft: '10px' }}>
                        {userName}
                    </Typography>
                    <ArrowDropDownIcon />

                    {isUserDropdownVisible && (
                        <div style={{
                            position: 'absolute',
                            right: 10,
                            top: 40,
                            backgroundColor: '#f9f9f9',
                            boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
                            zIndex: 150,
                            width: '200px', // Consistent sizing
                            padding: '0 0', // Padding on top and bottom for aesthetics
                        }}>
                            <Typography
                                variant="subtitle1"
                                component="div"
                                sx={{
                                    textAlign: 'center', // Centers the company name
                                    fontWeight: 'bold', // Makes the company name bold
                                    color: '#333',
                                    padding: '10px 0', // Adds padding around the company name
                                }}
                            >
                                {companyName}
                            </Typography>
                            <Typography
                                variant="body2"
                                component="div"
                                sx={{
                                    textAlign: 'center', // Centers the login time
                                    color: '#666',
                                    paddingBottom: '10px', // Adds padding at the bottom of login time
                                }}
                            >
                                Logged in since: {LogInTime}
                            </Typography>
                            <Divider sx={{ my: 0 }} />
                            <Box sx={{ display: 'flex', justifyContent: 'center', padding: '5px 0' }}>
                                <Chip
                                    label="Logout"
                                    onClick={handleLogoutClick}
                                    color="primary"
                                    clickable
                                    sx={{
                                        cursor: 'pointer', // Ensures it looks clickable
                                        fontWeight: 'bold', // Optional: makes text bold
                                    }}
                                />
                            </Box>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Header;