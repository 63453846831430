import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MuiCard from '@mui/material/Card';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import getSignUpTheme from './theme/getSignUpTheme';
import { SitemarkIcon } from './CustomIcons';
import TemplateFrame from './TemplateFrame';
import axios from "../common/axios"
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';



const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: 'auto',
  boxShadow:
    'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
  [theme.breakpoints.up('sm')]: {
    width: '450px',
  },
  ...theme.applyStyles('dark', {
    boxShadow:
      'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
  }),
}));

const SignUpContainer = styled(Stack)(({ theme }) => ({
  height: '100%',
  padding: 4,
  backgroundImage:
    'radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))',
  backgroundRepeat: 'no-repeat',
  ...theme.applyStyles('dark', {
    backgroundImage:
      'radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))',
  }),
}));

export default function SignUp() {
  const [mode, setMode] = React.useState('light');
  const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  const defaultTheme = createTheme({ palette: { mode } });
  const SignUpTheme = createTheme(getSignUpTheme(mode));
  const [emailError, setEmailError] = React.useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = React.useState('');
  // const [passwordError, setPasswordError] = React.useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const [passwordErrorMessage, setPasswordErrorMessage] = React.useState('');
  const [nameError, setNameError] = React.useState(false);
  const [nameErrorMessage, setNameErrorMessage] = React.useState('');
  const [showPasswordBox, setShowPasswordBox] = useState(false);
  const [showNextBox, setShowNextBox] = useState(false);
  const [username, setUsername] = useState('');
  const [isUserValid, setIsUserValid] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  const navigate = useNavigate();

  const handleCheckUsername = async () => {
    try {
      const response = await axios.get(`/checkuser?user=${encodeURIComponent(username)}`);
      if (response.data.isExist) {
        Swal.fire({
          icon: 'error',
          title: '사용자명 중복',
          text: '이미 사용 중인 사용자명입니다. 다른 사용자명을 입력해주세요.',
        }).then(() => {
          setIsUserValid(false);
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: '사용 가능한 사용자명',
          text: '입력하신 사용자명을 사용할 수 있습니다. 새로운 사용자명을 넣고 싶으면 새로고침을 하세요.',
        }).then(() => {
          setIsUserValid(true);
          setShowPasswordBox(true);
        });
      }
    } catch (error) {
      console.error('사용자명 중복 검사 중 오류 발생:', error);
      Swal.fire({
        icon: 'error',
        title: '오류 발생',
        text: '사용자명 중복 검사 중 오류가 발생했습니다. 다시 시도해주세요.',
      });
    }
  };
  const handlePasswordChange = (event) => {
    const password = event.target.value;
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!passwordRegex.test(password)) {
      setPasswordError(
        'Password must be at least 8 characters long, contain at least one uppercase letter, one special character, and must not contain consecutive characters or numbers.'
      );
    } else {
      setPasswordError('');
    }
    validateForm();
  };

  const handleConfirmPasswordChange = (event) => {
    const confirmPassword = event.target.value;
    const password = document.getElementById('password').value;
    if (confirmPassword !== password) {
      setConfirmPasswordError('Passwords do not match.');
    } else {
      setConfirmPasswordError('');
      setShowNextBox(true);

    }
    validateForm();
  };

  const validateForm = () => {
    const form = document.getElementById('signup-form');
    setIsFormValid(form.checkValidity() && isUserValid && !passwordError && !confirmPasswordError);
  };

  // This code only runs on the client side, to determine the system color preference
  React.useEffect(() => {
    // Check if there is a preferred mode in localStorage
    const savedMode = localStorage.getItem('themeMode');
    if (savedMode) {
      setMode(savedMode);
    } else {
      // If no preference is found, it uses system preference
      const systemPrefersDark = window.matchMedia(
        '(prefers-color-scheme: dark)',
      ).matches;
      setMode(systemPrefersDark ? 'dark' : 'light');
    }
  }, []);

  const toggleColorMode = () => {
    const newMode = mode === 'dark' ? 'light' : 'dark';
    setMode(newMode);
    localStorage.setItem('themeMode', newMode); // Save the selected mode to localStorage
  };

  const toggleCustomTheme = () => {
    setShowCustomTheme((prev) => !prev);
  };

 
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const username = data.get('username');
    const email = data.get('email');
    const name = data.get('name');
    const companyName = data.get('companyName');
    const password = data.get('password');

    try {
      const response = await axios.post('/signup', {
        username,
        email,
        name,
        companyName,
        password,
      });

      if (response.data.success) {
        Swal.fire({
          icon: 'success',
          title: '회원가입 성공',
          text: '회원가입이 성공적으로 완료되었습니다. LG화학 담당자의 승인 후에 로그인이 가능합니다. 승인이 되면 이메일로 알림이 갑니다.',
        }).then(() => {
          // 회원가입 성공 후 로그인 페이지로 이동
          navigate('/login');
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: '회원가입 실패',
          text: response.data.message,
        });
      }
    } catch (error) {
      console.error('회원가입 중 오류 발생:', error);
      Swal.fire({
        icon: 'error',
        title: '회원가입 실패',
        text: '회원가입 중 오류가 발생했습니다. 다시 시도해주세요.',
      });
    }
  };

  const handleUsernameChange = (event) => {
    const newUsername = event.target.value;
    setUsername(newUsername);
    setIsUserValid(false);
  };

  return (
    <TemplateFrame
      toggleCustomTheme={toggleCustomTheme}
      showCustomTheme={showCustomTheme}
      mode={mode}
      toggleColorMode={toggleColorMode}
    >
      <ThemeProvider theme={showCustomTheme ? SignUpTheme : defaultTheme}>
        <CssBaseline enableColorScheme />
        <SignUpContainer direction="column" justifyContent="space-between">
          <Stack
            sx={{
              justifyContent: 'center',
              height: '100dvh',
              p: 2,
            }}
          >
            <Card variant="outlined">
              <SitemarkIcon />
              <Typography
                component="h1"
                variant="h4"
                sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}
              >
                Sign up
              </Typography>
              <Box
                component="form" id="signup-form"
                onSubmit={handleSubmit}
                sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
              >
                <FormControl>
                  <FormLabel htmlFor="name">User Name</FormLabel>
                  <TextField
                    autoComplete="username"
                    name="username"
                    required
                    fullWidth
                    id="username"
                    placeholder="Login Name"
                    value={username}
                    error={nameError}
                    helperText={nameErrorMessage}
                    color={nameError ? 'error' : 'primary'}
                    onChange={handleUsernameChange}
                  />
                </FormControl>
                
                {!isUserValid && (
                  <Button
                    variant="contained"
                    onClick={handleCheckUsername}
                    disabled={!username}
                  >
                    Check Username
                  </Button>
                )}

                {isUserValid && (
                  <>
                    {/* Password fields */}
                    <FormControl>
                      <FormLabel htmlFor="password">Password</FormLabel>
                      <TextField
                        required
                        fullWidth
                        name="password"
                        placeholder="Password"
                        type="password"
                        id="password"
                        autoComplete="new-password"
                        error={!!passwordError}
                        helperText={passwordError}
                        onChange={handlePasswordChange}
                      />
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        type="password"
                        id="confirmPassword"
                        autoComplete="new-password"
                        error={!!confirmPasswordError}
                        helperText={confirmPasswordError}
                        onChange={handleConfirmPasswordChange}
                      />
                    </FormControl>

                    {/* Email, Company Name, and Full Name fields */}
                    {showNextBox && (
                      <>
                      <FormControl>
                        <FormLabel htmlFor="email">Email</FormLabel>
                        <TextField
                          required
                          fullWidth
                          id="email"
                          placeholder="your@email.com"
                          name="email"
                          autoComplete="email"
                          variant="outlined"
                          error={emailError}
                          helperText={emailErrorMessage}
                          color={emailError ? 'error' : 'primary'}
                          onChange={validateForm}
                        />
                      </FormControl>
                      
                      <FormControl>
                        <FormLabel htmlFor="name">Company Name</FormLabel>
                        <TextField
                          required
                          fullWidth
                          placeholder="LG화학"

                          id="companyName"
                          name="companyName"
                          autoComplete="organization"
                          onChange={validateForm}
                        />
                        </FormControl>

                        <FormControl>
                        <FormLabel htmlFor="full name">Full Name</FormLabel>
                        <TextField
                          required
                          fullWidth
                          id="name"
                          name="name"
                          autoComplete="name"
                          placeholder="김엘지"
                          onChange={validateForm}
                        />
                      </FormControl>
                      </>
                    )}

                    {/* Sign up button */}
                    {showNextBox && (
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        disabled={!isFormValid}
                      >
                        Sign up
                      </Button>
                    )}
                  </>
                )}

                <Typography sx={{ textAlign: 'center' }}>
                  Already have an account?{' '}
                  <span>
                    <Link
                      href="/login"
                      variant="body2"
                      sx={{ alignSelf: 'center' }}
                    >
                      Sign in
                    </Link>
                  </span>
                </Typography>
              </Box>
            </Card>
          </Stack>
        </SignUpContainer>
      </ThemeProvider>
    </TemplateFrame>
  );
}