import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import Swal from 'sweetalert2';
import dayjs from 'dayjs';
import { Box, ButtonGroup, Button, Alert, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreIcon from '@mui/icons-material/Restore';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import { darken } from '@mui/material/styles';
import {
    GridEditDateCell, gridClasses, GRID_CHECKBOX_SELECTION_COL_DEF, GRID_ROW_GROUPING_SINGLE_GROUPING_FIELD, GridCellParams,
    DataGridPremium, GridToolbarExport, GridToolbarContainer, GridToolbarQuickFilter, useKeepGroupedColumnsHidden,
    useGridApiRef, GridEditSingleSelectCell, GridActionsCellItem, GRID_ROOT_GROUP_ID, GridEditInputCell
} from '@mui/x-data-grid-premium';
import Snackbar from '@mui/material/Snackbar';
import {
    randomId,
} from '@mui/x-data-grid-generator';
import axios from "../common/axios"
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import _ from 'lodash';
import { useDropzone } from 'react-dropzone';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { IconButton, TextField, FormControl, InputLabel, Select, MenuItem, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import JSZip from 'jszip';

dayjs.extend(isSameOrBefore);

const TRTable = ({ initialRows, drawingData, issueStage, revData, Discipline, projectCode }) => {

    const handleUserAction = async (actionType, data) => {
        try {
          const description = `User performed ${actionType} action with data in TR: ${JSON.stringify(data)}`; // 로그 설명을 생성합니다.
    
          await axios.post('/logging', {
            description,
          });
        } catch (error) {
          console.error('Error logging user action:', error);
        }
      };


    const [openFileUpload, setOpenFileUpload] = useState(false);
    const [snackbar, setSnackbar] = React.useState(null);
    const apiRef = useGridApiRef();
    const [rows, setRows] = React.useState(initialRows.map((v) => ({ ...v, id: randomId() })));
    const [isSaving, setIsSaving] = React.useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [hasUnsavedRows, setHasUnsavedRows] = useState(false);
    const [prefix, setPrefix] = useState("");

    const [trNo, setTrNo] = useState("");
    const [trTitle, setTrTitle] = useState("");
    const [trDesc, setTrDesc] = useState("");
    const [trIssueDate, setTrIssueDate] = useState(new Date());
    const [dueDate, setDueDate] = useState(new Date());
    const [crmDiscipline, setCrmDiscipline] = useState("");


    // console.log(type of trIssueDate)
    const unsavedChangesRef = React.useRef({
        unsavedRows: {},
        rowsBeforeChange: {},
    });
    const [rowSelectionModel, setRowSelectionModel] = React.useState([]);


    const handleCloseSnackbar = () => setSnackbar(null);
    const handleOpenFileUpload = (rowId) => {
        setOpenFileUpload(rowId); // 열린 파일 업로드 대화상자의 rowId를 설정
    };

    const handleCloseFileUpload = () => setOpenFileUpload(false);


    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: (acceptedFiles) => {
            const newFiles = acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            }));
            setSelectedFiles(prevFiles => [...prevFiles, ...newFiles]);
        }
    });

    const getRowClassName = React.useCallback(({ id }) => {
        const unsavedRow = unsavedChangesRef.current.unsavedRows[id];
        if (unsavedRow) {
            if (unsavedRow._action === 'delete') {
                return 'row--removed';
            }
            return 'row--edited';
        }
        return '';
    }, []);

    const handleRemoveFile = (filePath) => {
        setSelectedFiles(prevFiles => prevFiles.filter(file => file.path !== filePath));
        handleUserAction('removeFile', { filePath });
    };
    //excel

    useEffect(() => {
        // 백엔드에서 prefix 받아오기
        const fetchPrefix = async () => {
          try {
            const response = await axios.get(`/trprefix?project=${projectCode}`); // 실제 API 엔드포인트로 대체해야 합니다.
            setPrefix(response.data.prefix);
          } catch (error) {
            console.error('Error fetching prefix:', error);
          }
        };
    
        fetchPrefix();
      }, []);

      useEffect(() => {
        if (prefix) {
          // initialRows에서 'TRNo'의 마지막 번호 찾기
          const prefixNumbers = initialRows
            .filter(row => row.TRNo && row.TRNo.startsWith(prefix))
            .map(row => {
              const parts = row.TRNo.split('-');
              return parseInt(parts[parts.length - 1] || '0', 10);
            });
      
          const lastTRNo = prefixNumbers.length > 0 ? Math.max(...prefixNumbers) : 0;
      
          // 마지막 번호에 1을 더한 값 계산 (4자리)
          const newTRNo = `${prefix}-${String(lastTRNo + 1).padStart(4, '0')}`;
      
          setTrNo(newTRNo);
        }
      }, [prefix, initialRows]);

    const columns = React.useMemo(() => {
        return [
            {
                field: 'actions',
                type: 'actions',
                renderCell: (params) => {
                    if (params.rowNode.type === "group" || params.row.record_no) {
                        return null; // 그룹된 행에서는 action 아이콘을 숨김
                    }
                    return (
                        <div>
                            <GridActionsCellItem
                                icon={<RestoreIcon />}
                                label="Discard changes"
                                disabled={unsavedChangesRef.current.unsavedRows[params.id] === undefined}
                                onClick={() => {
                                    const rowBeforeChange = unsavedChangesRef.current.rowsBeforeChange[params.id];
                                    if (rowBeforeChange) {
                                        apiRef.current.updateRows([rowBeforeChange]);
                                        delete unsavedChangesRef.current.rowsBeforeChange[params.id];
                                        delete unsavedChangesRef.current.unsavedRows[params.id];
                                        setHasUnsavedRows(Object.keys(unsavedChangesRef.current.unsavedRows).length > 0);
                                    }
                                }}
                            />
                            <GridActionsCellItem
                                icon={<DeleteIcon />}
                                label="Delete"
                                onClick={() => {
                                    unsavedChangesRef.current.unsavedRows[params.id] = {
                                        ...params.row,
                                        _action: 'delete',
                                    };
                                    if (!unsavedChangesRef.current.rowsBeforeChange[params.id]) {
                                        unsavedChangesRef.current.rowsBeforeChange[params.id] = params.row;
                                    }
                                    setHasUnsavedRows(true);
                                    apiRef.current.updateRows([params.row]); // to trigger row render
                                }}
                            />
                        </div>
                    );
                },
            },
            {
                field: 'fileAttached',
                headerName: '', // 제목 없음
                width: 50,
                renderCell: (params) => {
                    // fileRows 상태에서 현재 row의 id를 확인하여 파일 첨부 여부를 확인
                    const isFileAttached = params.row && params.row.isFileAttached;
                    return isFileAttached ? <AttachFileIcon sx={{ fontSize: 18 }} /> : '';
                },
            },

            { field: 'record_no', headerName: "Record No.", width: 100, editable: false },
            {
                field: 'TRNo',
                headerName: 'TR No',
                width: 100,
                editable: true,
                cellClassName: (params) => {
                    if (params.row.isEditMode) {
                        return 'matched'
                    }
                    return '';
                },
                renderEditCell: (params) => {

                    const disabled = params.row.record_no === undefined || params.row.record_no === null || params.row.record_no === "" ? true : false
                    // 편집 가능한 상태에서는 단일 선택 입력 컴포넌트를 렌더링
                    return (
                        <GridEditInputCell
                            {...params}
                            disabled={!disabled}
                        />
                    );

                },
                required: true,
            },


            {
                field: 'TRTitle',
                headerName: "TR Title",
                width: 100,
                editable: true, required: true,
                cellClassName: (params) => {
                    if (params.row.isEditMode) {
                        return 'matched'
                    }
                    return '';
                },
                renderEditCell: (params) => {

                    const disabled = params.row.record_no === undefined || params.row.record_no === null || params.row.record_no === "" ? true : false
                    // 편집 가능한 상태에서는 단일 선택 입력 컴포넌트를 렌더링
                    return (
                        <GridEditInputCell
                            {...params}
                            disabled={!disabled}
                        />
                    );

                },
            },

            {
                field: 'TRDesc',
                headerName: "Description",
                width: 150,
                editable: true,
                cellClassName: (params) => {
                    if (params.row.isEditMode) {
                        return 'matched'
                    }
                    return '';
                },
                renderEditCell: (params) => {

                    const disabled = params.row.record_no === undefined || params.row.record_no === null || params.row.record_no === "" ? true : false
                    // 편집 가능한 상태에서는 단일 선택 입력 컴포넌트를 렌더링
                    return (
                        <GridEditInputCell
                            {...params}
                            disabled={!disabled}
                        />
                    );

                },
            },
            {
                field: 'TRIssueDate',
                headerName: "TR Issue Date",
                width: 90,
                type: "date",
                cellClassName: (params) => {
                    if (params.row.isEditMode) {
                        return 'matched'
                    }
                    return '';
                },
                editable: true, required: true,
                renderEditCell: (params) => {

                    const disabled = params.row.record_no === undefined || params.row.record_no === null || params.row.record_no === "" ? true : false
                    // 편집 가능한 상태에서는 단일 선택 입력 컴포넌트를 렌더링
                    return (
                        <GridEditDateCell
                            {...params}
                            disabled={!disabled}
                        />
                    );

                },
            },

            {
                field: 'ugenDueDateDO',
                headerName: "Due Date",
                width: 90,
                type: "date",
                editable: true, required: true,
                cellClassName: (params) => {
                    if (params.row.isEditMode) {
                        return 'matched'
                    }
                    return '';
                },
                renderEditCell: (params) => {

                    const disabled = params.row.record_no === undefined || params.row.record_no === null || params.row.record_no === "" ? true : false
                    // 편집 가능한 상태에서는 단일 선택 입력 컴포넌트를 렌더링
                    return (
                        <GridEditDateCell
                            {...params}
                            disabled={!disabled}
                        />
                    );

                },
            },
            {
                field: 'status',
                headerName: "TR Status",
                width: 90,
                editable: false,
            },
            {
                field: 'CRMDisciplineBPK',
                headerName: "Discipline",
                width: 90,
                editable: true, required: true,
                type: 'singleSelect',
                valueOptions: Discipline,
                cellClassName: (params) => {
                    if (params.row.isEditMode) {
                        return 'matched'
                    }
                    return '';
                },
                renderEditCell: (params) => {

                    const disabled = params.row.record_no === undefined || params.row.record_no === null || params.row.record_no === "" ? true : false
                    // 편집 가능한 상태에서는 단일 선택 입력 컴포넌트를 렌더링
                    return (
                        <GridEditSingleSelectCell
                            {...params}
                            disabled={!disabled}
                        />
                    );

                },
            },

            {
                field: 'ugenSubRecNoPK',
                headerName: "Deliverable No.",
                width: 180,
                editable: false,
            },
            { field: 'ugenSubTitlSDT120', headerName: "Deliverable Title", width: 250, editable: false },
            {
                field: 'IssueStage', headerName: "Issue Stage", width: 80, editable: true,
                cellClassName: (params) => {
                    if (params.row.isEditMode) {
                        return 'matched'
                    }
                    return '';
                },
                renderEditCell: (params) => {

                    const disabled = params.row.record_no === undefined || params.row.record_no === null || params.row.record_no === "" ? true : false
                    // 편집 가능한 상태에서는 단일 선택 입력 컴포넌트를 렌더링
                    return (
                        <GridEditSingleSelectCell
                            {...params}
                            disabled={!disabled}
                        />
                    );

                },
                required: true, type: 'singleSelect', valueOptions: ({ row }) =>
                    _.sortBy(issueStage.find(v => v.ISGroupName === row.IssueStageGroupDPK)?._bp_lineitems, "IssueOrderDA")
                        .map(vf => vf.IssueStageDPK) || []

            },
            {
                field: 'usubRevNumberTB8',
                headerName: "Rev",
                width: 80,
                editable: true,
                cellClassName: (params) => {
                    if (params.row.isEditMode) {
                        return 'matched'
                    }
                    return '';
                },
                renderEditCell: (params) => {

                    const disabled = params.row.record_no === undefined || params.row.record_no === null || params.row.record_no === "" ? true : false
                    // 편집 가능한 상태에서는 단일 선택 입력 컴포넌트를 렌더링
                    return (
                        <GridEditSingleSelectCell
                            {...params}
                            disabled={!disabled}
                        />
                    );

                },
                required: true,
                type: 'singleSelect',
                valueOptions: ({ id, row }) => {
                    const revType = issueStage
                        .find(v => v.ISGroupName === row.IssueStageGroupDPK)?._bp_lineitems
                        .find(vf => vf.IssueStageDPK === row.IssueStage)?.RevSeqDPK;

                    if (revType) {
                        const revItem = revData.find(vff => vff.title === revType);
                        if (revItem && revItem._bp_lineitems) {
                            return revItem._bp_lineitems.map(v => v.usubRevNumberTB8);
                        }
                    }

                    return []; // revType이 없거나 revItem이 없는 경우 빈 배열 반환
                },
            },


        ];
    }, [unsavedChangesRef, apiRef]);


    function EditToolbar() {

        const isProjectApplyDisabled = React.useMemo(() => {
            const newRows = rows.filter((row) => row.record_no === undefined);
            const requiredColumns = columns.filter((column) => column.required);

            if (newRows.length === 0 || rows.length === 0 || hasUnsavedRows) {
                return true; // record_no가 undefined인 행이 없으면 버튼 비활성화
            }

            return newRows.some((row) =>
                requiredColumns.some((column) => !row[column.field])
            );
        }, [rows, columns]);

        return (
            <GridToolbarContainer>
                <Button color="primary" startIcon={<AddIcon />} onClick={handleOpenFileUpload}>
                    Add records
                </Button>
                <Button
                    color="secondary"
                    startIcon={<SaveIcon />}
                    disabled={!hasUnsavedRows}
                    loading={isSaving}
                    onClick={saveChanges}
                >
                    Save All
                </Button>
                <Button
                    disabled={!hasUnsavedRows || isSaving}
                    onClick={discardChanges}
                    startIcon={<RestoreIcon />}
                >
                    Discard all changes
                </Button>
                {/* 
                <Button color="secondary" startIcon={<ArrowDownwardIcon />} onClick={applyValuesToAll}>
                    Apply Values
                </Button> */}
                <div style={{ display: 'flex', gap: '10px', alignItems: 'center', position: "absolute", right: 12 }}>
                    <Button
                        type='button'
                        // style={{ display: rows.length === 0 || hasUnsavedRows ? "none" : "block" }}
                        variant="contained"
                        onClick={handleRequest}
                        size="small"
                        disabled={isProjectApplyDisabled}
                    // disabled={isEditing}  // 편집 중인 행이 있으면 버튼 비활성화
                    >
                        PMAP 전송
                    </Button>
                </div>
            </GridToolbarContainer>
        );
    }

    const handleUploadFiles = async () => {

        setTrNo('');
        setTrTitle('');
        setTrDesc('');
        setTrIssueDate(null);
        setCrmDiscipline('');
        setSelectedFiles([]);
        setOpenFileUpload(true);

        const newRows = []; // 새로운 행을 저장할 빈 배열을 초기화합니다.

        for (const file of selectedFiles) {
            let fileNameWithoutExtension = file.name;
            const lastDotIndex = fileNameWithoutExtension.lastIndexOf('.');
            if (lastDotIndex > 0) {
                fileNameWithoutExtension = fileNameWithoutExtension.substring(0, lastDotIndex);
            }

            const splitChars = [' ', '.', '_'];
            let minIndex = fileNameWithoutExtension.length; // 파일 이름의 길이를 초기 최소 인덱스 값으로 설정

            for (const char of splitChars) {
                const index = fileNameWithoutExtension.indexOf(char);
                if (index > -1 && index < minIndex) {
                    minIndex = index; // 가장 작은 인덱스 업데이트
                }
            }

            if (minIndex !== fileNameWithoutExtension.length) { // 구분자가 존재하는 경우
                fileNameWithoutExtension = fileNameWithoutExtension.substring(0, minIndex);
            }


            const drawing = drawingData.find(v => v.DeliverableNotxt === fileNameWithoutExtension);

            // drawingData에서 파일 이름을 찾지 못한 경우
            if (!drawing) {
                // SweetAlert를 사용하여 경고 메시지 표시
                Swal.fire({
                    icon: 'error',
                    text: `첨부된 파일의 도서 번호 ${fileNameWithoutExtension}를 "Project Deliverable"에서 찾을 수 없습니다.`,
                });
                continue; // 현재 파일 처리를 건너뛰고 다음 파일로 넘어갑니다.
            }


            const newId = randomId();

            newRows.push({
                id: newId,
                DeliPkgNo:drawing.DeliPkgNo,
                usubpPackgNameTB120:drawing.usubpPackgNameTB120,
                ugenSubRecNoPK: fileNameWithoutExtension,
                isNew: true,
                isFileAttached: true,
                ugenSubTitlSDT120: drawing.ugenSubTitlSDT120,
                IssueStageGroupDPK: drawing.IssueStageGroupDPK,
                file: file,
                isEditMode: true,
                TRTitle: trTitle, // Add the TRTitle from state
                TRDesc: trDesc, // Add the TRDesc from state
                TRIssueDate: trIssueDate, // Add the TRIssueDate from state
                CRMDisciplineBPK: crmDiscipline,
                TRNo: trNo,
                ugenDueDateDO: dueDate,
            });

            // setRowModesModel(oldModel => ({ ...oldModel, [newId]: { mode: GridRowModes.Edit } }));
        }

        // setRows 함수 내에서 기존 행들과 신규 행을 병합한 뒤, 정렬을 수행
        setRows((oldRows) => {
            const mergedRows = [...newRows, ...oldRows];
            // 신규 행을 배열의 앞쪽으로 이동
            mergedRows.sort((a, b) => {
                if (a.isNew && !b.isNew) {
                    return -1; // a가 신규 행이면 앞으로 이동
                } else if (!a.isNew && b.isNew) {
                    return 1; // b가 신규 행이면 a를 뒤로 이동
                } else {
                    // 두 행 모두 신규이거나 기존 행인 경우, 기존의 레코드 번호 등 다른 기준으로 정렬
                    return a.record_no - b.record_no;
                }
            });
            return mergedRows;
        });
        setHasUnsavedRows(true);

        newRows.forEach((row) => {
            unsavedChangesRef.current.unsavedRows[row.id] = row;
        });


        handleCloseFileUpload();
        handleUserAction('uploadFiles', {
            selectedFiles: selectedFiles.map(file => file.name),
            trNo,
            trTitle,
            trDesc,
            trIssueDate,
            crmDiscipline,
          }); // 로그 기록
        
    };

    const initialState = {
        rowGrouping: {
            model: ['TRNo'],
        },
    }

    const dropzoneStyle = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: '2px',
        borderRadius: '2px',
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out',
        cursor: 'pointer',
        width: '400px', // 너비 고정
        boxSizing: 'border-box', // 패딩과 보더가 너비에 포함되도록 설정

    };

    const activeStyle = {
        borderColor: '#2196f3',
    };


    const discardChanges = React.useCallback(() => {
        setHasUnsavedRows(false);

        const rowsToUpdate = Object.values(unsavedChangesRef.current.rowsBeforeChange).filter(
            (row) => !unsavedChangesRef.current.unsavedRows[row.id]?.isNew
        );


        apiRef.current.updateRows(rowsToUpdate);

        unsavedChangesRef.current = {
            unsavedRows: {},
            rowsBeforeChange: {},
        };
    }, [apiRef]);


    const saveChanges = React.useCallback(async () => {
        try {
            setIsSaving(false);

            const updatedRows = Object.values(unsavedChangesRef.current.unsavedRows);


            const deletedRowIds = updatedRows
                .filter((row) => row._action === 'delete')
                .map((row) => row.id);

            const requiredFields = ['TRNo', 'TRTitle', 'TRIssueDate', 'ugenDueDateDO', 'CRMDisciplineBPK', 'IssueStage', 'usubRevNumberTB8'];
            const requiredFieldsName = ['TR No', 'TR Title', 'TR Issue Date', 'Due Date', 'Discipline', 'Issue Stage', 'Rev'];

            const missingFields = updatedRows.filter((row) =>
                requiredFields.some((field) => !row[field] && !deletedRowIds.includes(row.id))
            );

            if (missingFields.length > 0) {
                const missingFieldsMessage = missingFields
                    .map((row) => {
                        const missingFieldNames = requiredFields
                            .map((field, index) => (!row[field] ? requiredFieldsName[index] : null))
                            .filter((name) => name !== null)
                            .join(', ');

                        return `No ${row.ugenSubRecNoPK}: ${missingFieldNames}`;
                    })
                    .join('\n');

                setSnackbar({
                    children: `필수값이 없습니다:\n${missingFieldsMessage}`,
                    severity: 'error',
                });
                return;
            }

            setRows((prevRows) => {
                const existingRows = prevRows.map((row) => {
                    const updatedRow = updatedRows.find((r) => r.id === row.id);
                    if (updatedRow && updatedRow._action !== 'delete') {
                        return {
                            ...row,
                            ...updatedRow,
                            isNew: false,
                            isEditMode: false
                        };
                    }
                    return row;
                });

                const newRows = updatedRows.filter(
                    (row) => !prevRows.some((r) => r.id === row.id) && row._action !== 'delete'
                )

                return [...existingRows, ...newRows].filter(
                    (row) => !deletedRowIds.includes(row.id)
                );
            });

            setHasUnsavedRows(false);


            unsavedChangesRef.current = {
                unsavedRows: {},
                rowsBeforeChange: {},
            };
        } catch (error) {
            setIsSaving(false);
        }

        handleUserAction('saveChanges', {
            updatedRows: Object.values(unsavedChangesRef.current.unsavedRows),
          }); // 로그 기록

    }, [apiRef]);

    const handleRequest = async () => {
        let hasError = false; // 오류 추적용 변수
        let errorMessage = ''; // 오류 메시지 저장용 변수

        const createData = rows.filter(v => v.record_no === undefined)
            .map(row => {
                return Object.entries(row).reduce((newRow, [key, value]) => {
                    if (value instanceof Date) {
                        newRow[key] = dayjs(value).format("MM-DD-YYYY");
                    } else {
                        newRow[key] = value;
                    }
                    return newRow;
                }, {});
            });

        var transformedUpdateData = Object.values(createData.reduce((acc, item) => {
            if (!acc[item.TRNo]) {
                acc[item.TRNo] = {
                    TRNo: item.TRNo,
                    TRTitle: item.TRTitle,
                    TRIssueDate: item.TRIssueDate,
                    CRMDisciplineBPK: item.CRMDisciplineBPK,
                    DeliPkgNo:item.DeliPkgNo,
                    usubpPackgNameTB120:item.usubpPackgNameTB120,
                    ugenDueDateDO: item.ugenDueDateDO,
                    TRDesc: item.TRDesc,
                    _bp_lineitems: []
                };
            }
            acc[item.TRNo]._bp_lineitems.push({
                ugenSubRecNoPK: item.ugenSubRecNoPK,
                ugenSubTitlSDT120: item.ugenSubTitlSDT120,
                usubRevNumberTB8: item.usubRevNumberTB8,
                IssueStage: item.IssueStage,
                uuu_name: item.file.name,
                _attachment: [{
                    file_name: item.file.name,
                    "title": item.file.name,
                    "issue_date": dayjs().format('DD/MM/YYYY'),
                    "revision_no": "000"
                }],
                file: item.file
            });
            return acc;
        }, {}));

        // 사용자 확인 요청
        const confirmed = await Swal.fire({
            title: 'Confirm Data Transmission',
            text: `${transformedUpdateData.length} TR(s) will be transmitted. This action cannot be undone. Do you want to transmit ${transformedUpdateData.length} TR data?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Transmit',
            cancelButtonText: 'Cancel'
        }).then((result) => {
            return result.isConfirmed;
        });
        if (confirmed) {
            for (const row of transformedUpdateData) {
                const zip = new JSZip();

                // _bp_lineitems에 있는 파일들을 ZIP 파일에 추가
                for (const lineItem of row._bp_lineitems) {
                    if (lineItem.file) {
                        console.log(lineItem.file.name, lineItem.file)
                        zip.file(lineItem.file.name, lineItem.file);
                    }
                }

                // ZIP 파일 생성 및 Base64 인코딩
                const zipBlob = await zip.generateAsync({ type: 'blob' });
                const zipBase64 = await toBase64(zipBlob);

                // ZIP 파일 정보와 해당 row 데이터를 포함하는 요청 본문 생성
                const requestBody = {
                    options: {
                        bpname: "TR to Client",
                    },
                    data: [{
                        ...row,
                        _bp_lineitems: row._bp_lineitems.map(item => {
                            const { file, ...rest } = item;
                            return rest;
                        }),

                    }],
                    _attachment: {
                        zipped_file_name: "create.zip",
                        zipped_file_size: zipBlob.size.toString(),
                        zipped_file_content: zipBase64,
                    }
                };

                try {
                    const response = await axios.post(`/trwithdocument?path=${projectCode}`, requestBody, {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });

                    if (!response.data.success) {
                        hasError = true;
                        errorMessage = response.data.data; // 오류 메시지 저장
                        break; // 에러가 발생하면 더 이상 진행하지 않음
                    }
                } catch (error) {
                    hasError = true;
                    errorMessage = error.response ? error.response.data.message : `${row.TRTitle} 데이터 전송 중 오류가 발생했습니다.`;
                    console.error(`Error sending data to the server for ${row.TRTitle}:`, error);
                    break; // 에러가 발생하면 더 이상 진행하지 않음
                }
            }

            // 모든 요청이 완료된 후 결과에 따라 팝업 표시
            if (!hasError) {
                handleUserAction('TR 데이터 전송', {
                    transmittedTRs: transformedUpdateData.map(row => row.TRNo),
                  }); // 로그 기록
                Swal.fire({
                    icon: 'success',
                    title: '성공!',
                    text: '모든 데이터가 성공적으로 전송되었습니다.',
                }).then(() => {
                    window.location.reload(); // 성공 팝업 후 페이지 새로고침
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: '오류 발생',
                    text: errorMessage,
                });
            }
        }
    };


    const columnGroupingModel = useMemo(() => [
        {
            groupId: "group",
            headerName: "Transmittal",
            freeReordering: true,
            description: '', // You can add descriptions if needed
            children: [{ field: "TRNo" }, { field: "DisciplineTxT" }, { field: "TRTitle" },
            { field: "TRDesc" }, { field: "TRIssueDate" }, { field: "ugenDueDateDO" }, { field: "status" }, { field: "CRMDisciplineBPK" },]
        },


    ], []);


    const processRowUpdate = React.useCallback((newRow, oldRow) => {
        const rowId = newRow.id;

        unsavedChangesRef.current.unsavedRows[rowId] = newRow;
        if (!unsavedChangesRef.current.rowsBeforeChange[rowId]) {
            unsavedChangesRef.current.rowsBeforeChange[rowId] = oldRow;
        }
        setHasUnsavedRows(true);
        return newRow;
    }, []);

    return (
        <>
            <Box
                sx={{
                    height: 'calc(100vh - 120px)',
                    backgroundColor: '#fff',
                    borderRadius: "14px",
                    width: '100%',
                    "& .MuiDataGrid-root": {
                        border: 0
                    },
                    "& .MuiDataGrid-row.Mui-hovered": {
                        backgroundColor: "transparent",
                    },
                    // Take out the hover colour
                    "& .MuiDataGrid-row:hover": {
                        backgroundColor: "transparent",
                    },
                    '& .MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 'bold', // 헤더 셀의 글자를 진하게 설정
                    },
                    '& .MuiDataGrid-cell': {
                        fontSize: 12,
                    },
                    '& .notmatched': {
                        backgroundColor: '#f2f3f3',
                    },
                    '& .matched': {
                        backgroundColor: 'rgba(255, 254, 176, 0.3)!important',
                    },
                    '& .groupRow': {
                        backgroundColor: '#fff',
                    },



                    // '& .notmatched.MuiDataGrid-cell--editing': {
                    //     backgroundColor: '#f2f3f3',
                    //     color: '#f2f3f3'
                    // },
                    '& .notmatched input': {
                        // backgroundColor: '#F0EDE5', 
                        fontSize: 0
                    },
                    '& .notmatched.MuiDataGrid-cell': {
                        backgroundColor: '#f2f3f3',
                    },
                    '& .actions': {
                        color: 'text.secondary',
                    },
                    '& .textPrimary': {
                        color: 'text.primary',
                    },

                    // '& .required-cell': {
                    //     backgroundColor: 'rgba(255, 254, 176, 0.3)',
                    //   },
                }}
            >

                <DataGridPremium
                    apiRef={apiRef}
                    editMode="cell"
                    rows={rows}
                    rowHeight={38}
                    initialState={initialState}
                    columns={columns}
                    cellSelection
                    pagination
                    autoPageSize
                    defaultGroupingExpansionDepth={-1}
                    disableRowSelectionOnClick
                    experimentalFeatures={{ columnGrouping: true }}
                    columnGroupingModel={columnGroupingModel}
                    processRowUpdate={processRowUpdate}
                    rowSelectionModel={rowSelectionModel}
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        setRowSelectionModel(newRowSelectionModel);
                    }}
                    density="compact"
                    slots={{ toolbar: EditToolbar }}
                    groupingColDef={{
                        headerName: 'Group',
                    }}
                    onCellEditStart={(params) => {
                        if (params.colDef.required && params.row.record_no === undefined) {
                            const columnHeader = document.querySelector(
                                `[data-field="${params.field}"] .MuiDataGrid-columnHeaderTitle`
                            );
                            if (columnHeader) {
                                columnHeader.classList.add('header-required');
                            }
                        }
                    }}
                    onCellEditStop={(params) => {
                        const columnHeader = document.querySelector(
                            `[data-field="${params.field}"] .MuiDataGrid-columnHeaderTitle`
                        );
                        if (columnHeader) {
                            columnHeader.classList.remove('header-required');
                        }
                    }}
                    sx={{
                        '& .row--removed:hover': {
                            backgroundColor: "transparent",
                        },
                        [`& .${gridClasses.columnHeader} .MuiDataGrid-columnHeaderTitle`]: {
                            fontSize: '11px', // 헤더 셀의 글자 크기 변경
                        },
                        [`& .MuiDataGrid-cell`]: {
                            fontSize: '10px', // 행의 글자 크기 변경  
                        },

                        [`& .${gridClasses.row}.row--removed`]: {
                            backgroundColor: (theme) => {
                                if (theme.palette.mode === 'light') {
                                    return 'rgba(255, 170, 170, 0.3)!important';
                                }
                                return darken('rgba(255, 170, 170, 1)', 0.7);
                            },
                            '&:hover': {
                                backgroundColor: (theme) => {
                                    if (theme.palette.mode === 'light') {
                                        return 'rgba(255, 170, 170, 0.3)';
                                    }
                                    return darken('rgba(255, 170, 170, 1)', 0.7);
                                }
                            },
                        },

                    }}
                    loading={isSaving}
                    getRowClassName={getRowClassName}

                />

                {!!snackbar && (
                    <Snackbar
                        open
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        onClose={handleCloseSnackbar}
                    // autoHideDuration={6000}
                    >
                        <Alert {...snackbar} onClose={handleCloseSnackbar} />
                    </Snackbar>
                )}
            </Box>

            {openFileUpload && (
                <Dialog open={Boolean(openFileUpload)} onClose={handleCloseFileUpload}
                    PaperProps={{
                        style: {
                            padding: '8px',
                            overflowX: 'hidden'
                        },
                    }}>
                    <DialogTitle sx={{ padding: "12px 8px" }}>New Transmittal</DialogTitle>
                    <DialogContent sx={{ padding: '8px', width: "400px" }}>
                        <Box mb={2}>
                            <Typography variant="h8">TR Information</Typography>
                            <TextField
                                label="TR No."
                                fullWidth
                                margin="normal"
                                value={trNo}
                                InputProps={{
                                    readOnly: true,
                                  }}
                                required size="small"
                                // error={rows.some(row => row.TRNo === trNo)}
                                // helperText={rows.some(row => row.TRNo === trNo) ? "This TR No. already exists" : ""}
                            />
                            <TextField
                                label="TR Title"
                                fullWidth
                                margin="normal"
                                value={trTitle}
                                onChange={(e) => setTrTitle(e.target.value)}
                                required size="small"
                            />
                            <TextField
                                label="TR Description"
                                fullWidth
                                margin="normal"
                                value={trDesc}
                                onChange={(e) => setTrDesc(e.target.value)}
                                required size="small"
                            />
                            <TextField
                                label="TR Issue Date"
                                type="date"
                                fullWidth
                                margin="normal"
                                value={dayjs(trIssueDate).format("YYYY-MM-DD")}
                                onChange={(e) => setTrIssueDate(e.target.value ? new Date(e.target.value) : null)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    inputProps: {
                                        min: dayjs().format("YYYY-MM-DD") // 오늘 날짜를 최소값으로 설정
                                    }
                                }}
                                required
                                size="small"
                            />
                            <FormControl fullWidth margin="normal" required size="small">
                                <InputLabel id="discipline-label">Discipline</InputLabel>
                                <Select
                                    labelId="discipline-label"
                                    value={crmDiscipline}
                                    onChange={(e) => setCrmDiscipline(e.target.value)}
                                    label="Discipline"
                                >
                                    {Discipline.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>

                        <Box>
                            <Typography variant="h8">Deliverables Upload</Typography>
                            <div {...getRootProps()} style={{ ...dropzoneStyle, ...(isDragActive ? activeStyle : {}), width: '100%', boxSizing: 'border-box' }}>
                                <input {...getInputProps()} />
                                {
                                    isDragActive ?
                                        <p>Drop the files here ...</p> :
                                        <p>Drag & drop files here, or click here to select files.</p>
                                }
                            </div>
                            <ul style={{ width: "94%", paddingInlineStart: '20px', marginBlockStart: '8px' }}>
                                {selectedFiles.map(file => (
                                    <li key={file.path} style={{ fontSize: 12 }}>
                                        {file.path} - {file.size} bytes&nbsp;
                                        <IconButton sx={{ p: 0 }} onClick={() => handleRemoveFile(file.path)}> <HighlightOffIcon sx={{ fontSize: 14 }} /> </IconButton>
                                    </li>
                                ))}
                            </ul>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseFileUpload}>Cancel</Button>
                        <Button
                            onClick={handleUploadFiles}
                            disabled={!trTitle || !trDesc || !trIssueDate || !crmDiscipline || selectedFiles.length === 0}
                        >
                            Create
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );

}

export default TRTable

const toBase64 = (blob) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result.split(',')[1]); // 결과에서 Base64 문자열만 추출
    reader.onerror = error => reject(error);
});