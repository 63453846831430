import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import Sidebar from './Sidebar';

const Layout = () => {
  const [isSidebarVisible, setSidebarVisible] = useState(true);
  const headerHeight = '53.5px';

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header />
      <div style={{ display: 'flex', flex: '1' }}>
        <Sidebar isSidebarVisible={isSidebarVisible} setSidebarVisible={setSidebarVisible}/>
        <main style={{
          flex: 1,
          marginTop: headerHeight,
          transition: 'margin-left 0.3s, margin-top 0.3s',
          padding: '1rem',
          overflow: 'hidden',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column'
        }}>
          <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: '#F0EDE5',
            zIndex: -1
          }} />
          <div style={{ flex: 1, overflow: 'auto' }}>
            <Outlet />
          </div>
        </main>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;