import React, { useState } from 'react';
import { animated, useSpring } from '@react-spring/web';
import { useNavigate } from 'react-router-dom';
import { useTreeViewApiRef, RichTreeView} from '@mui/x-tree-view';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import KeyboardDoubleArrowLeftRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftRounded';
import KeyboardDoubleArrowRightRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowRightRounded';
import { styled, alpha } from '@mui/material/styles';
import { treeItemClasses } from '@mui/x-tree-view/TreeItem';
import { unstable_useTreeItem2 as useTreeItem2 } from '@mui/x-tree-view/useTreeItem2';
import {
  TreeItem2Checkbox,
  TreeItem2Content,
  TreeItem2IconContainer,
  TreeItem2Label,
  TreeItem2Root,
} from '@mui/x-tree-view/TreeItem2';
import { TreeItem2Icon } from '@mui/x-tree-view/TreeItem2Icon';
import { TreeItem2Provider } from '@mui/x-tree-view/TreeItem2Provider';
import ArticleIcon from '@mui/icons-material/Article';
import DeleteIcon from '@mui/icons-material/Delete';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import FolderRounded from '@mui/icons-material/FolderRounded';
import ImageIcon from '@mui/icons-material/Image';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';
import { useRecoilValue,useSetRecoilState } from 'recoil';
import { selectedProjectState, selectedMenuLabelState } from '../recoil/atoms';
import axios from "../common/axios";



function DotIcon() {
  return (
    <Box
      sx={{
        width: 6,
        height: 6,
        borderRadius: '70%',
        bgcolor: 'warning.main',
        display: 'inline-block',
        verticalAlign: 'middle',
        zIndex: 1,
        mx: 1,
      }}
    />
  );
}



const StyledTreeItemRoot = styled(TreeItem2Root)(({ theme }) => ({
  color:
    theme.palette.mode === 'light'
      ? theme.palette.grey[800]
      : theme.palette.grey[400],
  position: 'relative',
  [`& .${treeItemClasses.groupTransition}`]: {
    marginLeft: theme.spacing(3.5),
  },
}));

const CustomTreeItemContent = styled(TreeItem2Content)(({ theme }) => ({
  flexDirection: 'row-reverse',
  borderRadius: theme.spacing(0.7),
  marginBottom: theme.spacing(0.5),
  marginTop: theme.spacing(0.5),
  padding: theme.spacing(0.5),
  paddingRight: theme.spacing(1),
  fontWeight: 500,
  [`&.Mui-expanded `]: {
    '&:not(.Mui-focused, .Mui-selected, .Mui-selected.Mui-focused) .labelIcon': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.primary.main
          : theme.palette.primary.dark,
    },
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: '16px',
      top: '44px',
      height: 'calc(100% - 48px)',
      width: '1.5px',
      backgroundColor:
        theme.palette.mode === 'light'
          ? theme.palette.grey[300]
          : theme.palette.grey[700],
    },
  },
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    color: theme.palette.mode === 'light' ? theme.palette.primary.main : 'white',
  },
  [`&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused`]: {
    backgroundColor:
      theme.palette.mode === 'light'
        ? theme.palette.primary.main
        : theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
  },
}));

const AnimatedCollapse = animated(Collapse);

function TransitionComponent(props) {
  const style = useSpring({
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(0,${props.in ? 0 : 20}px,0)`,
    },
  });

  return <AnimatedCollapse style={style} {...props} />;
}

const StyledTreeItemLabelText = styled(Typography)({
  color: 'inherit',
  fontFamily: 'General Sans',
  fontWeight: 500,
});

function CustomLabel({ icon: Icon, expandable, children, ...other }) {
  return (
    <TreeItem2Label
      {...other}
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {Icon && (
        <Box
          component={Icon}
          className="labelIcon"
          color="inherit"
          sx={{ mr: 1, fontSize: '1.2rem' }}
        />
      )}

      <StyledTreeItemLabelText variant="body2">{children}</StyledTreeItemLabelText>
      {expandable && <DotIcon />}
    </TreeItem2Label>
  );
}
const expandedWidth = '16rem';
const collapsedWidth = '6px';
const headerHeight = '53px';




const isExpandable = (reactChildren) => {
  if (Array.isArray(reactChildren)) {
    return reactChildren.length > 0 && reactChildren.some(isExpandable);
  }
  return Boolean(reactChildren);
};

const getIconFromFileType = (fileType) => {
  switch (fileType) {
    case 'image':
      return ImageIcon;
    case 'pdf':
      return PictureAsPdfIcon;
    case 'doc':
      return ArticleIcon;
    case 'video':
      return VideoCameraBackIcon;
    case 'folder':
      return FolderRounded;
    case 'pinned':
      return FolderOpenIcon;
    case 'trash':
      return DeleteIcon;
    default:
      return ArticleIcon;
  }
};

const CustomTreeItem = React.forwardRef(function CustomTreeItem(props, ref) {
  const { id, itemId, label, disabled, children, ...other } = props;

  const {
    getRootProps,
    getContentProps,
    getIconContainerProps,
    getCheckboxProps,
    getLabelProps,
    getGroupTransitionProps,
    status,
    publicAPI,
  } = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref });

  const item = publicAPI.getItem(itemId);
  const expandable = isExpandable(children);
  let icon;
  if (expandable) {
    icon = FolderRounded;
  } else if (item.fileType) {
    icon = getIconFromFileType(item.fileType);
  }

  return (
    <TreeItem2Provider itemId={itemId}>
      <StyledTreeItemRoot {...getRootProps(other)}>
        <CustomTreeItemContent
          {...getContentProps({
            className: clsx('content', {
              'Mui-expanded': status.expanded,
              'Mui-selected': status.selected,
              'Mui-focused': status.focused,
              'Mui-disabled': status.disabled,
            }),
          })}
        >
          <TreeItem2IconContainer {...getIconContainerProps()}>
            <TreeItem2Icon status={status} />
          </TreeItem2IconContainer>
          <TreeItem2Checkbox {...getCheckboxProps()} />
          <CustomLabel
            {...getLabelProps({ icon, expandable: expandable && status.expanded })}
          />
        </CustomTreeItemContent>
        {children && <TransitionComponent {...getGroupTransitionProps()} />}
      </StyledTreeItemRoot>
    </TreeItem2Provider>
  );
});

function Sidebar() {
  const navigate = useNavigate();
  const apiRef = useTreeViewApiRef();
  const [sidebarWidth, setSidebarWidth] = useState(expandedWidth);
  const selectedProject = useRecoilValue(selectedProjectState);
  const setSelectedLabel = useSetRecoilState(selectedMenuLabelState); // Hook to set the atom

  const [userType, setUserType] = useState("");


React.useEffect(() => {
  const fetchData = async () => {
      try {
          const userResponse = await axios.get(`/getUserType`);
          setUserType(userResponse.data.userType); // Set user data using Recoil
      } catch (userError) {
          console.error("Error fetching user data:", userError);
          // Handle error in fetching user data
      }
  }
  fetchData()
}, [])

const handleItemSelection = (event, itemIds, isSelected) => {
  const item = apiRef.current.getItem(itemIds);
  if (item.path) {
    navigate(`/${item.path}`);
    setSelectedLabel(item.label); // Update the Recoil state with the label of the selected item
  }
};



const MenuList = [
  ...(userType.includes("Engineering Deliverables") ? [
    {
      id: '1', 
      label: 'EDMS',
      fileType: 'folder',
      children: [
        { id: '1.1', label: 'Master Deliverable Register', path: `mdl`, fileType: '' },
        { id: '1.2', label: 'Transmittal', path: `tr`, fileType: '' },
      ],
    },
    {
      id: '2',
      label: 'Vendor Print', 
      fileType:'folder',
      children: [
        { id: '2.1', label: 'Vendor Print Register', path: `vp`, fileType: '' },
        { id: '2.2', label: 'Transmittal for Vendor Print', path: `vtr`, fileType: '' },
      ],
    },
  ] : []),
  ...(userType.includes("Vendor Print") && !userType.includes("Engineering Deliverables") ? [
    {
      id: '2',
      label: 'Vendor Print', 
      fileType:'folder',
      children: [
        { id: '2.1', label: 'Vendor Print Register', path: `vp`, fileType: '' },
        { id: '2.2', label: 'Transmittal for Vendor Print', path: `vtr`, fileType: '' },
      ],
    },
  ] : []),
];

const getAllItemsWithChildrenItemIds = () => {
  const itemIds = [];
  const registerItemId = (item) => {
    if (item.children?.length) {
      itemIds.push(item.id);
      item.children.forEach(registerItemId);
    }
  };

  MenuList.forEach(registerItemId);

  return itemIds;
};

  const handleToggleNav = () => {
    setSidebarWidth(sidebarWidth === expandedWidth ? collapsedWidth : expandedWidth);
  };

  const [expandedItems, setExpandedItems] = React.useState(getAllItemsWithChildrenItemIds());

  const handleExpandedItemsChange = (event, itemIds) => {
    setExpandedItems(itemIds);
  };

  const handleExpandClick = () => {
    setExpandedItems((oldExpanded) =>
      oldExpanded.length === 0 ? getAllItemsWithChildrenItemIds() : [],
    );
  };


  return (
    <Box sx={{
      width: sidebarWidth,
      transition: 'width 0.3s',
      overflow: 'hidden',
      position: 'relative',
      top: headerHeight,
      height: `calc(100vh - ${headerHeight})`,
      display: 'flex',
      flexDirection: 'column',
      borderRight:'1px solid #D0D5DD'
      
    }}>
      {sidebarWidth === expandedWidth && (
        <Stack spacing={4} sx={{ flex: 1, minHeight: '0',padding:2, bgcolor: 'background.paper' }}>
          <RichTreeView
            items={MenuList}
            apiRef={apiRef}
            slots={{ item: CustomTreeItem }}
            expandedItems={expandedItems}
            onExpandedItemsChange={handleExpandedItemsChange}
            // onItemSelectionToggle={(event, itemIds, isSelected) => {
            //   // You can handle item selection here if needed
            //   const item = apiRef.current.getItem(itemIds);
            //   if (item.path) {
            //     navigate(`/${item.path}`)
            //     // console.log(item.path)
            //   }
            // }}
            onItemSelectionToggle={handleItemSelection} // Use the new handler here

            multiSelect={false} // Set to true if you need multi-selection
            checkboxSelection={false} // Enable if you want checkboxes
          />
        </Stack>
      )}

        <Button onClick={handleToggleNav}
          sx={{
            position: 'fixed',
            bottom: 2,
            left: 0,
            margin: 0,
            padding: 0,
            paddingBottom: 1
          }}
          startIcon={
            sidebarWidth === expandedWidth ?
              <KeyboardDoubleArrowLeftRoundedIcon style={{ color: 'lightgray' }} /> : // Any hex color
              <KeyboardDoubleArrowRightRoundedIcon style={{ color: 'lightgray' }} /> // Any hex color
          } />

        <Button onClick={handleExpandClick}
          sx={{
            position: 'fixed',
            bottom: 2,
            left: sidebarWidth !== expandedWidth ? -100 : `calc(16rem - 64px)`,
            margin: 0,
            padding: 0,
            paddingBottom: 1
          }}
          startIcon={
            expandedItems.length === 0 ?
              <AddCircleOutlineRoundedIcon style={{ color: 'lightgray' }} /> : // Any hex color
              <RemoveCircleOutlineRoundedIcon style={{ color: 'lightgray' }} /> // Any hex color
          } />

    </Box>
  );
}

export default Sidebar;